import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  AccordionDetails,
  Backdrop,
  CircularProgress,
  Link,
  Dialog,
  DialogContent,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';

import QuestionMark from '../../assets/insurance/QuestionMark.png';
import QuestionMark2 from '../../assets/insurance/QuestionMark@2x.png';
import QuestionMark3 from '../../assets/insurance/QuestionMark@3x.png';
import GoldSheild from '../../assets/insurance/gold-sheild.png';
import GoldSheild2 from '../../assets/insurance/gold-sheild@2x.png';
import GoldSheild3 from '../../assets/insurance/gold-sheild@3x.png';
import PlatSheild from '../../assets/insurance/plat-sheild.png';
import PlatSheild2 from '../../assets/insurance/plat-sheild@2x.png';
import PlatSheild3 from '../../assets/insurance/plat-sheild@3x.png';
import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';
import Survey2Img from '../../assets/insurance/Survey2Img.png';
import Survey2Img2 from '../../assets/insurance/Survey2Img@2x.png';
import Survey2Img3 from '../../assets/insurance/Survey2Img@3x.png';
import Survey1Img from '../../assets/insurance/Survey1Img.png';
import Survey1Img2 from '../../assets/insurance/Survey1Img@2x.png';
import Survey1Img3 from '../../assets/insurance/Survey1Img@3x.png';
import ErrorIcon from '../../assets/insurance/serial-error.png';
import ErrorIcon2 from '../../assets/insurance/serial-error@2x.png';
import ErrorIcon3 from '../../assets/insurance/serial-error@3x.png';
import SuccessIcon from '../../assets/insurance/serial-success.png';
import SuccessIcon2 from '../../assets/insurance/serial-success@2x.png';
import SuccessIcon3 from '../../assets/insurance/serial-success@3x.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrimaryButton from '../../components/primary-button/PrimaryButton';
import { Footer } from '../../components/footer/Footer';
import { isNumber } from 'lodash';
import { EmbeddedInsuranceService } from '@zenown-insurance/services/requests';
import { LanguageSection } from '../../components/zenown-insurance/LanguageSection';
import HowDoesWorks from '../../components/zenown-insurance/howDoesWorks';
import PolicyPage from '../../components/zenown-insurance/PolicyPage';
import Questionnaire from './Questionnaire';
const embeddedInsuranceService = new EmbeddedInsuranceService();

const PlanComponenet = ({
  title,
  isLoading,
  step,
  isCalculating,
  icon,
  color,
  price,
  onSelect,
  paragraphs,
  disabled,
  recommended,
  onSelectText,
  priceLabel,
  onScroll,
}: any) => {
  const scroll = useRef<any>();
  const [scrolling, setScrolling] = useState(true);

  return (
    <Box
      key={price}
      sx={{
        background: 'white',
        paddingTop: '24px',
        paddingBottom: '14px',
        paddingInline: '16px',
        border: '2px solid ' + color,
        borderRadius: '10px',
        minHeight: price ? '700px' : '600px',
        maxHeight: '700px',
        width: '411px',
        maxWidth: '411px',
        position: 'relative',
      }}
    >
      {Boolean(recommended) && step === 2 && (
        <div
          style={{
            zIndex: 0,
            top: '-32px',
            left: 0,
            right: 0,
            marginInline: '20%',
            width: '259',
            maxHeight: '32px',
            height: '32px',
            paddingTop: '0px',

            position: 'absolute',
            background: '#0179FF',
            borderRadius: '8px 8px 0px 0px',
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontSize: '12px',
              fontWeight: '600',
              textAlign: 'center',
              lineHeight: '16.2px',
              marginTop: '9px',
            }}
          >
            {recommended}
          </Typography>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        {' '}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            zIndex: 2,
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ width: '47px', height: '47px', marginRight: '24px' }}>
            {icon}
          </div>
          <Typography
            sx={{ fontWeight: '900px', fontSize: '24px', lineHeight: '32px' }}
          >
            {title}
          </Typography>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Typography
            sx={{
              fontWeight: '500px',
              fontSize: '14px',
              lineHeight: '18.9px',
              color: '#9CA0AB',
            }}
          >
            {priceLabel}
          </Typography>
          {(Boolean(price) || isCalculating) && step === 2 && (
            <Typography
              sx={{
                fontWeight: '800px',
                fontSize: '16px',
                lineHeight: '21.6px',
                color: '#9CA0AB',
              }}
            >
              {Boolean(price) && <>{Number(price).toFixed(2)} €</>}
            </Typography>
          )}
        </div>
      </div>
      <div
        ref={scroll}
        onScroll={() => setScrolling(scrolling)}
        style={{
          marginTop: '26px',
          maxHeight: '510px',

          minHeight: price ? '510px' : '510px',
          overflowY: 'scroll',
          position: 'relative',
        }}
      >
        {paragraphs.map((p: any) => (
          <Accordion
            sx={{
              border: 'none',
              outline: 'none',
              borderTop: '1px solid #f2f3f5',
              paddingInline: '0',
              boxShadow: 'none',
              borderRadius: 0,
              textAlign: 'left',
            }}
          >
            <AccordionSummary
              sx={{
                height: '47px',
                border: 'none',
                outline: 'none',
                textAlign: 'left',
              }}
              onClick={() => onScroll()}
              expandIcon={<ExpandMoreIcon />}
            >
              <CheckIcon
                sx={{ width: '13px', marginRight: '13px', color: color }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '21.6px',
                  color: '#9CA0AB',
                }}
              >
                {p.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ /* background: '#f2f3f5',*/ marginBottom: '0px' }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '21.6px',
                  color: '#9CA0AB',
                  opacity: '1',
                  textAlign: 'left',
                }}
              >
                {p.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}{' '}
        <div
          style={{
            height: '20px',
          }}
        ></div>
      </div>{' '}
      {(Boolean(price) || isCalculating) && step === 2 && (
        <Typography
          sx={{
            position: 'absolute',
            bottom: '80px',
            width: '100%',
            left: '0',
            zIndex: '99',
            fontWeight: '600x',
            fontSize: '32px',
            lineHeight: '43.2px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '5px',
          }}
        >
          {isCalculating && (
            <div style={{}}>
              <CircularProgress color="inherit" />
            </div>
          )}
          {Boolean(price) && <>{Number(price).toFixed(2)} €</>}
        </Typography>
      )}
      {false &&
        scroll?.current?.scrollTop < scroll?.current?.scrollHeight - 20 && (
          <div
            style={{
              position: 'absolute',
              bottom: '115px',
              width: '100%',
              left: '0',
              zIndex: '99',
              height: '50px',
              background:
                'linear-gradient(183.37deg, rgba(255, 255, 255, 0) 2.78%, #FFFFFF 82.24%)',
            }}
          ></div>
        )}
      {step === 2 && (
        <>
          {' '}
          <PrimaryButton
            sx={{
              opacity: '0',
              marginTop: '70px',
              width: '100%',
            }}
            loadingLogic={false}
            disabled={disabled}
            onClick={() => {
              onSelect();
            }}
          >
            {onSelectText}
          </PrimaryButton>
          <PrimaryButton
            sx={{
              position: 'absolute',
              bottom: '14px',
              width: '411px',
            }}
            loadingLogic={true}
            isLoading={isLoading}
            disabled={disabled}
            onClick={() => {
              onSelect();
            }}
          >
            {onSelectText}
          </PrimaryButton>
        </>
      )}
    </Box>
  );
};
function HomePage() {
  const {
    t,
    data,
    setData,
    setCurrentStep,
    homeStep,
    setHomeStep,
    trackEvent,
  } = useContext<any>(InsuranceDesktopContext);
  const [isLoading, setIsLoading] = useState(false);

  const [receiptLoading, setReceiptLoading] = useState(false);
  const [howitworks, setHowitworks] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [terms, setTerms] = useState(false);
  const onselect = async (premium: boolean) => {
    try {
      //setReceiptLoading(true);
      setData({ ...data, isPremium: premium });
      /* if (!data.receipt) {
        const receiptData = await embeddedInsuranceService.getReceipt();
        setData({ ...data, receipt: receiptData?.data });
      }*/
      setCurrentStep(1);
    } catch (err) {
    } finally {
      //setReceiptLoading(false);
    }
  };

  const getPremiumOptions = useCallback(
    debounce(async (price: number) => {
      try {
        setIsLoading(true);
        // Assuming the implementation of the getPremiumOptions API call
        const response = await embeddedInsuranceService.getPremiumOptions(
          price
        );
        if (response?.status === 'success') {
          setData({
            ...data,
            bike: {
              ...data?.bike,
              premiumSubscriptionPrice: +response.data[1]?.premium,
              subscriptionPrice: +response.data[0]?.premium,
              price: +price,
            },
            offres: response.data,
          });
        }
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    }, 500),
    []
  );
  const [price, setPrice] = useState<any>();
  if (homeStep === 1)
    return (
      <>
        <Questionnaire />
      </>
    );
  else
    return (
      <div style={{ marginBottom: '33px' }}>
        <Box
          sx={{
            padding: '32px',
            background: '#FFFFFF',
            marginTop: '24px',
            maxWidth: '408px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            marginInline: 'auto',

            boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
            borderRadius: '10px',
          }}
        >
          <label style={{ textAlign: 'left', marginBottom: '11px' }}>
            {t('inssurancecalculator.label')}
          </label>
          <TextField
            value={price}
            //type="number"
            sx={{
              '& input[type=number]': {
                '-moz-appearance': 'textfield',
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
            }}
            onChange={(e) => {
              trackEvent({ event: 'Input', action: 'Bike Price' });
              let p = e.target.value
                .replace(/[^0-9.,]+/g, '') // Remove any characters that are not numbers, commas, or periods
                .replace('.', ',');
              setPrice(p);
            }}
            variant="outlined"
            placeholder={'0'}
            InputProps={{
              inputMode: 'decimal',
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          {homeStep === 0 && (
            <PrimaryButton
              loadingLogic={true}
              isLoading={isLoading}
              disabled={!price || isLoading}
              sx={{ marginTop: '24px' }}
              onClick={() => {
                trackEvent({ event: 'Button click', action: 'Get Started' });
                setHomeStep(1);
                getPremiumOptions(+price.replace(',', '.'));
              }}
            >
              {t('getStarted')}
            </PrimaryButton>
          )}
        </Box>

        <Box
          sx={{
            marginTop: '48px',
            marginBottom: '56px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'start',
            marginInline: 'auto',
            gap: '20px',
          }}
        >
          <PlanComponenet
            {...{
              title: t('inssurancecalculator.goldPlan.title'),
              isCalculating: isLoading,
              isLoading: receiptLoading,
              step: homeStep,
              priceLabel: t('inssurancecalculator.price'),
              onScroll: () => {
                trackEvent({
                  event: 'Dropdown Menu',
                  action: 'Gold Coverage description',
                });
              },
              icon: (
                <img
                  src={GoldSheild}
                  srcSet={`${GoldSheild} 1x, ${GoldSheild2} 2x, ${GoldSheild3} 3x`}
                  style={{
                    width: '47px',
                    height: '47px',
                  }}
                />
              ),
              color: '#CF9828',
              price: data?.bike?.subscriptionPrice,
              recommended:
                data?.surveyAnswers?.survey1 === false ||
                data?.surveyAnswers?.survey2 === false
                  ? t('inssurancecalculator.recommended')
                  : undefined,
              onSelect: () => {
                trackEvent({
                  event: 'Button click',
                  action: 'Get Started (Gold Coverage)',
                });
                onselect(false);
              },
              paragraphs: [1, 2, 3, 4, 5, 6, 7, 8].map((i) => ({
                title: t(`inssurancecalculator.goldPlan.paragraph.${i}.title`),
                description: t(
                  `inssurancecalculator.goldPlan.paragraph.${i}.description`
                ),
              })),
              disabled:
                data?.surveyAnswers?.survey1 === undefined ||
                data?.surveyAnswers?.survey2 === undefined ||
                !data?.bike?.price,
              onSelectText: t('inssurancecalculator.selectPlan'),
            }}
          />
          <PlanComponenet
            {...{
              title: t('inssurancecalculator.platPlan.title'),
              isCalculating: isLoading,
              isLoading: receiptLoading,
              priceLabel: t('inssurancecalculator.price'),
              step: homeStep,
              onScroll: () => {
                trackEvent({
                  event: 'Dropdown Menu',
                  action: 'Plat Coverage description',
                });
              },
              icon: (
                <img
                  src={PlatSheild}
                  srcSet={`${PlatSheild} 1x, ${PlatSheild2} 2x, ${PlatSheild3} 3x`}
                  style={{
                    width: '47px',
                    height: '47px',
                  }}
                />
              ),
              color: '#8DA4D2',
              recommended:
                data?.surveyAnswers?.survey1 === true &&
                data?.surveyAnswers?.survey2 === true
                  ? t('inssurancecalculator.recommended')
                  : undefined,
              price: +data?.bike?.premiumSubscriptionPrice,
              onSelect: () => {
                trackEvent({
                  event: 'Button click',
                  action: 'Get Started (Plat Coverage)',
                });
                onselect(true);
              },
              paragraphs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => ({
                title: t(`inssurancecalculator.platPlan.paragraph.${i}.title`),
                description: t(
                  `inssurancecalculator.platPlan.paragraph.${i}.description`
                ),
              })),
              disabled:
                data?.surveyAnswers?.survey1 === undefined ||
                data?.surveyAnswers?.survey2 === undefined ||
                !data?.bike?.price,
              onSelectText: t('inssurancecalculator.selectPlan'),
            }}
          />
        </Box>

        <div
          style={{
            marginBottom: '24px',
            marginInline: 'auto',
            textAlign: 'center',
          }}
        >
          <Link
            underline="none"
            onClick={() => {
              trackEvent({
                event: 'Button click',
                action: 'How does it work?',
              });
              setHowitworks(true);
            }}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <img
              src={QuestionMark}
              srcSet={`${QuestionMark} 1x, ${QuestionMark2} 2x, ${QuestionMark3} 3x`}
              style={{ marginRight: '8px', height: '18px', width: '18px' }}
            />
            <div>
              {' '}
              <Typography
                onClick={() => {}}
                sx={{
                  fontWeight: '500!important',
                  fontSize: '14px!important',
                  lineHeight: '21px !important',
                  color: '#383A49',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {t('inssurancecalculator.how')}
              </Typography>
            </div>
          </Link>
        </div>
        <div
          style={{
            marginBottom: '72px',
            marginInline: 'auto',
            textAlign: 'center',
          }}
        >
          <LanguageSection trackEvent={trackEvent} />
        </div>

        <Footer />
        {privacy && (
          <PolicyPage
            trackEvent={trackEvent}
            onClose={() => {
              setPrivacy(false);
            }}
          />
        )}
        {howitworks && !privacy && (
          <HowDoesWorks
            showPolicy={setPrivacy}
            trackEvent={trackEvent}
            onClose={() => {
              setHowitworks(false);
            }}
          />
        )}
      </div>
    );
}

export default HomePage;
