import { Container, Grid, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import styles from '../../containers/zenown-reader.module.scss';
import ZenOwnLogo from '../../assets/reader/ZenOwn logo.svg';
import ElementLogo from '../../assets/insurance/ElementLogo.png';
import ElementLogo2 from '../../assets/insurance/ElementLogo@2x.png';
import ElementLogo3 from '../../assets/insurance/ElementLogo@3x.png';
import { useContext } from 'react';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';
import { SpringValue } from 'react-spring';
import MainDesktopScreen from '../../pages/desktopInsurance/MainDesktopScreen';
import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';
export const Footer = (props: any) => {
  const { t } = useContext(InsuranceDesktopContext);
  return (
    <div>
      <Typography
        variant="caption"
        color="#9CA0AB"
        sx={{
          fontSize: '12px',
          fotnWeight: '400',
          lineHeight: '18px',
          letterSpacing: '-0.3px',
        }}
      >
        {t('inssurancecalculator.footer')}
      </Typography>
      <br />
      <img
        src={ElementLogo}
        srcSet={`${ElementLogo} 1x, ${ElementLogo2} 2x, ${ElementLogo3} 3x`}
        style={{
          marginTop: '13px',
          width: '101px',
          height: '26px',
        }}
      />
    </div>
  );
};
