import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  AccordionDetails,
  Link,
  Grid,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { enUS, de } from 'date-fns/locale';
import './datePickerStyles.css';
import { format } from 'date-fns';

import React, { useContext, useEffect, useRef, useState } from 'react';

import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';

import { Controller, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { EmbeddedInsuranceService } from '@zenown-insurance/services/requests';
import PrimaryButton from '../../components/primary-button/PrimaryButton';
const embeddedInsuranceService = new EmbeddedInsuranceService();
/*const formatDate = (dateString: any) => {
  const parts = dateString.split('.');
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];
  return `${year}-${month}-${day}`;
};*/
const formatDate = (date: any) => {
  if (!new Date(date).getTime()) return '';
  return format(new Date(date), 'dd.mm.yyyy');
};
const useAddUser = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const addUser = async (params: any) => {
    try {
      const response = await embeddedInsuranceService.createPolicy(params);
      setData(response.data);
      setError(null);
      return response;
    } catch (err: any) {
      setError(err);
      return { err };
    } finally {
    }
  };

  return { data, isLoading, error, addUser, setIsLoading };
};
function UserInfo() {
  const {
    t,
    data,
    setData,
    STEPS,
    currentStep,
    setCurrentStep,
    setupPayement,
    trackEvent,
  } = useContext<any>(InsuranceDesktopContext);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [dateOpen, setDateOpen] = useState(false);
  const {
    data: userData,
    isLoading,
    error,
    addUser,
    setIsLoading,
  } = useAddUser();
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Personal Info',
    });
  }, []);
  const onsubmit = async (userInfo: any) => {
    try {
      trackEvent({
        event: 'Button click',
        action: 'Next',
      });
      setIsLoading(true);
      let { data: policyData } = await addUser({
        ...userInfo,
        answers:
          (data?.surveyAnswers?.survey1 ? 'Y' : 'N') +
          (data?.surveyAnswers?.survey2 ? 'Y' : 'N'),
        lang: localStorage.getItem('i18nextLng'),
        product_id: data.bike.uuid,
        premium_id: data.offres[data.isPremium ? 1 : 0].id,
        premium_price: data.offres[data.isPremium ? 1 : 0].premium,
      });
      if (policyData) {
        setData({
          ...data,
          user: userInfo,
          policyId: policyData.policy_id,
        });
        await setupPayement(policyData.policy_id);
        setCurrentStep(3);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    formState,
    clearErrors,
    setError,
    setValue,
  } = useForm({
    shouldFocusError: true,
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    mode: 'onChange',
    defaultValues: { ...data?.user, country: 'Germany' },
  });
  useEffect(() => {
    watch();
  }, [errorMessage]);
  const today = new Date();
  const minDate = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate()
  );
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  const fields = [
    {
      id: 'firstName',
      label: t('personalinfo.firstName'),
      placeholder: 'John',
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('firstName.errorRequired'),
        },
        minLength: {
          value: 2,
          message: t('firstName.errorInvalid'),
        },
      },
    },
    {
      id: 'lastName',
      label: t('personalinfo.lastName'),
      placeholder: 'Smith',
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('lastName.errorRequired'),
        },
        minLength: {
          value: 2,
          message: t('lastName.errorInvalid'),
        },
      },
    },

    {
      id: 'dob',
      label: t('personalinfo.dateOfBirth'),
      placeholder: '01.01.2003',
      type: 'date',
      rules: {
        required: {
          value: true,
          message: t('dateOfBirth.errorRequired'),
        },
        validate: (value: any) => {
          const selectedDate = new Date(value);
          return (
            (selectedDate >= minDate && selectedDate <= maxDate) ||
            'The date must be between 18 and 100 years ago'
          );
        },
      },
    },
    {
      id: 'email',
      label: t('personalinfo.email'),
      placeholder: 'johnsmith@example.com',
      type: 'email',
      rules: {
        required: {
          value: true,
          message: t('email.errorRequired'),
        },
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: t('email.errorInvalid'),
        },
      },
    },
    {
      id: 'country',
      label: t('personalinfo.country'),

      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('country.errorRequired'),
        },
      },
    },
    {
      id: 'state',
      label: t('personalinfo.state'),
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('state.errorRequired'),
        },
      },
    },
    {
      id: 'city',
      label: t('personalinfo.city'),
      placeholder: 'Munich',
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('city.errorRequired'),
        },
      },
    },
    {
      id: 'postal_code',
      label: t('personalinfo.postal_code'),
      placeholder: '12345',
      type: 'text',
      rules: {
        required: {
          value: true,
          message: t('postal_code.errorRequired'),
        },
      },
    },
    {
      id: 'address',
      label: t('personalinfo.address'),
      type: 'text',
      placeholder: 'Example address 123',
      rules: {
        required: {
          value: true,
          message: t('address.errorRequired'),
        },
        minLength: {
          value: 3,
          message: t('address.errorInvalid'),
        },
      },
    },
  ];

  const RenderInput = (fieldKey: string, order: any) => {
    const field: any = fields.find((k) => k.id === fieldKey);
    return (
      <>
        {field.rendering && false ? (
          field.rendering()
        ) : (
          <Controller
            name={field.id}
            control={control}
            rules={field.rules}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => (
              <div
                style={{
                  marginBottom: '11px',
                }}
              >
                <label style={{}}>{field.label}</label>
                <TextField
                  sx={{ marginTop: '11px' }}
                  defaultValue={''}
                  error={Boolean(error)}
                  placeholder={field.placeholder}
                  InputProps={{
                    ...(field.type == 'date'
                      ? {
                          endAdornment: (
                            <div
                              style={{
                                pointerEvents: 'none',
                                marginTop: '5px',
                              }}
                              onClick={() => {}}
                            >
                              <CalendarTodayIcon />
                            </div>
                          ),
                        }
                      : {}),
                    ...(field.id === 'email'
                      ? { type: 'email' }
                      : field.type == 'date'
                      ? { type: 'date' }
                      : {}),
                    tabIndex: field.id === 'country' ? -1 : order,
                    style: {
                      textTransform:
                        field.id === 'email'
                          ? 'lowercase'
                          : field.type === 'date'
                          ? 'uppercase'
                          : 'capitalize',
                    },
                  }}
                  {...(field.id === 'email' ? { type: 'email' } : {})}
                  inputProps={{
                    // tabIndex: field.id === 'country' ? -1 : order,
                    autofocus: true,

                    inputProps:
                      field.type == 'date'
                        ? {
                            endAdornment: (
                              <div
                                style={{ pointerEvents: 'none' }}
                                onClick={() => {}}
                              ></div>
                            ),
                            max: moment()
                              .subtract(18, 'years')
                              .format('DD.MM.YYYY'),
                            min: moment()
                              .subtract(100, 'years')
                              .format('DD.MM.YYYY'),
                            pattern: '\\d{2}\\.\\d{2}\\.\\d{4}',
                            value: formatDate(value),
                            placeholder: 'DD.MM.YYYY',
                          }
                        : {},

                    style: {
                      textTransform:
                        field.id === 'email'
                          ? 'lowercase'
                          : field.type === 'date'
                          ? 'uppercase'
                          : 'capitalize',
                    },
                  }}
                  fullWidth
                  onChange={(e: any) => {
                    trackEvent({
                      event: 'Input',
                      action: field.id,
                    });
                    if (field.id === 'dob' && e.target.value?.length > 10)
                      return;
                    if (field.id === 'country') return;
                    if (error) {
                      setError(field.id, error);
                      setErrorMessage(error?.message + '');
                    } else {
                      if (!errorMessage) {
                        clearErrors(field.id);
                        setErrorMessage('');
                      }
                    }
                    onChange(e);
                  }}
                  onKeyUp={(e: any) => {
                    if (field.id === 'city') {
                      e.preventDefault();

                      // e.target.blur();
                    }
                  }}
                  onBlur={(e: any) => {
                    if (getValues()[field?.id]) clearErrors(field.id);
                    if (field.id === 'city') {
                      if (document?.activeElement) {
                        setTimeout(() => {
                          //@ts-ignore
                          document?.activeElement?.blur();
                        }, 0);
                        //@ts-ignore
                        document?.activeElement?.blur();
                      }
                      onBlur();
                    }
                    //onBlur();
                  }}
                  value={
                    field.id === 'country'
                      ? 'Germany'
                      : field.id === 'date'
                      ? format(value, 'dd/MM/yyyy')
                      : value
                  }
                />
              </div>
            )}
          />
        )}
      </>
    );
  };
  return (
    <div style={{ marginBottom: '33px', position: 'relative' }}>
      <form
        onSubmit={handleSubmit(async (res: any) => {
          await setTimeout(() => {
            setData({ ...data, user: res });
          }, 0);
          await onsubmit(res);
        })}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={
            localStorage.getItem('i18nextLng') === 'de' ? de : enUS
          }
          localeText={{
            cancelButtonLabel: t('cancel'),
            okButtonLabel: t('confirm'),
          }}
        >
          {' '}
          <Box
            sx={{
              padding: '32px',
              background: '#FFFFFF',
              marginTop: '24px',
              maxWidth: '408px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              marginInline: 'auto',

              boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
              borderRadius: '10px',
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="stretch"
              sx={{ textAlign: 'left' }}
            >
              {' '}
              <Grid item>{RenderInput('firstName', 1)}</Grid>
              <Grid item>{RenderInput('lastName', 2)}</Grid>{' '}
              <Grid item sx={{ marginBottom: '20px', position: 'relative' }}>
                {RenderInput('dob', 3)}
              </Grid>
              <Grid item sx={{ marginBottom: '20px' }}>
                {RenderInput('email', 4)}
              </Grid>
              <Grid item sx={{ marginBottom: '20px' }}>
                {RenderInput('address', 5)}
              </Grid>
              <Grid
                item
                container
                spacing={2}
                columns={16}
                sx={{ marginBottom: '20px' }}
              >
                <Grid item xs={8}>
                  {RenderInput('postal_code', 6)}
                </Grid>
                <Grid item xs={8}>
                  {RenderInput('city', 7)}
                </Grid>
              </Grid>
              <Grid item sx={{ marginBottom: '20px' }}>
                {RenderInput('country', undefined)}
              </Grid>
            </Grid>
          </Box>
        </LocalizationProvider>
        <div
          style={{
            marginTop: '32px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            gap: '14px',
            maxWidth: STEPS[currentStep].maxWidth,
            marginInline: 'auto',
          }}
        >
          <Button
            onClick={() => {
              trackEvent({
                event: 'Button click',
                action: 'Back',
              });
              STEPS[currentStep].previousAction(currentStep);
            }}
            sx={{
              borderRadius: '10px',
              background: 'rgba(156, 160, 171, 0.08)',
              height: '56px',
              width: '235px',
            }}
          >
            <Typography
              sx={{
                color: '#9CA0AB',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '21.6px',
              }}
            >
              {t('back')}
            </Typography>
          </Button>
          <PrimaryButton
            variant="contained"
            isLoading={isLoading}
            loadingLogic={true}
            sx={{
              background: '#0179FF',
              backgroundColor: '#0179FF',
              borderRadius: '10px',
              height: '56px',
              width: '235px',
              opacity: formState.isValid ? 1 : 0.5,
            }}
            disabled={!formState.isValid}
            type="submit"
          >
            <Typography
              sx={{
                color: 'white',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '21.6px',
              }}
            >
              {t('next')}
            </Typography>
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}

export default UserInfo;
