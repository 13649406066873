import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  AccordionDetails,
  Link,
  List,
  ListItemSecondaryAction,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, { useContext, useEffect, useRef, useState } from 'react';
import QuestionMark from '../../assets/insurance/QuestionMark.png';
import QuestionMark2 from '../../assets/insurance/QuestionMark@2x.png';
import QuestionMark3 from '../../assets/insurance/QuestionMark@3x.png';
import GoldSheild from '../../assets/insurance/gold-sheild.png';
import GoldSheild2 from '../../assets/insurance/gold-sheild@2x.png';
import GoldSheild3 from '../../assets/insurance/gold-sheild@3x.png';
import PlatSheild from '../../assets/insurance/plat-sheild.png';
import PlatSheild2 from '../../assets/insurance/plat-sheild@2x.png';
import PlatSheild3 from '../../assets/insurance/plat-sheild@3x.png';
import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';
import Survey2Img from '../../assets/insurance/Survey2Img.png';
import Survey2Img2 from '../../assets/insurance/Survey2Img@2x.png';
import Survey2Img3 from '../../assets/insurance/Survey2Img@3x.png';
import Survey1Img from '../../assets/insurance/Survey1Img.png';
import Survey1Img2 from '../../assets/insurance/Survey1Img@2x.png';
import Survey1Img3 from '../../assets/insurance/Survey1Img@3x.png';
import ErrorIcon from '../../assets/insurance/serial-error.png';
import ErrorIcon2 from '../../assets/insurance/serial-error@2x.png';
import ErrorIcon3 from '../../assets/insurance/serial-error@3x.png';
import SuccessIcon from '../../assets/insurance/serial-success.png';
import SuccessIcon2 from '../../assets/insurance/serial-success@2x.png';
import SuccessIcon3 from '../../assets/insurance/serial-success@3x.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrimaryButton from '../../components/primary-button/PrimaryButton';
import { Footer } from '../../components/footer/Footer';
import { isNumber } from 'lodash';
import moment from 'moment';
import {
  getInsurancePriceForQuote,
  getPriceFormatted,
} from '@zenown-insurance/services/requests';
import StripePayForm from '../../components/zenown-insurance/StripePayForm';

function CheckoutStep() {
  const { t, data, STEPS, currentStep, setData, isLoading, trackEvent } =
    useContext<any>(InsuranceDesktopContext);
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Summary & Checkout',
    });
  }, []);
  let quote: any = {
    price: data?.bike?.subscriptionPrice,
    premium: data?.isPremium,
    premiumPrice: data?.isPremium
      ? data?.bike?.premiumSubscriptionPrice
      : data?.bike?.subscriptionPrice,
  };
  let item = {
    price: data?.bike?.price,
    brand: data?.bike?.model,
    ...data?.bike,
  };
  let userInfo = data?.user;
  const paragraphs = [
    {
      title: t('checkoutstep.articles.2.title'),
      body: [
        {
          label: t('checkoutstep.articles.2.name'),
          value: userInfo?.firstName + ' ' + userInfo?.lastName,
        },

        {
          label: t('checkoutstep.articles.2.email'),
          value: userInfo?.email,
        },
        {
          label: t('checkoutstep.articles.2.address'),
          value: ' ' + userInfo?.address,
        },
        {
          label: t('checkoutstep.articles.2.zip&city'),
          value: ' ' + userInfo?.postal_code + ' ' + userInfo?.city,
        },
      ],
    },
    {
      title: t('checkoutstep.articles.1.title'),
      body: [
        {
          label: t('checkoutstep.articles.1.package'),
          value: quote?.premium
            ? t('checkoutstep.articles.1.platinum')
            : t('checkoutstep.articles.1.gold'),
        },

        {
          label: t('checkoutstep.articles.1.product'),
          value: item?.brand ? item?.brand + ' ' : +item?.name,
        },
        {
          label: t('checkoutstep.articles.1.price'),
          value: getPriceFormatted(item?.price || 0),
        },
        {
          label: t('checkoutstep.articles.1.premiumPrice'),
          value: getInsurancePriceForQuote(quote),
        },
        {
          label: t('checkoutstep.articles.1.start'),
          value: moment().format('DD.MM.YYYY'),
        },
      ],
      renewal: t('checkoutstep.articles.1.renewal'),
    },
  ];
  return (
    <div style={{ marginBottom: '33px' }}>
      <Box
        sx={{
          padding: '32px',
          background: '#FFFFFF',
          marginTop: '24px',
          maxWidth: '408px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          marginInline: 'auto',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
        }}
      >
        {paragraphs.map((expandedData: any, i: any) => (
          <>
            <Typography
              sx={{
                textTransform: 'uppercase',
                color: '#383A49',
                fontSize: '14px',
                fontWeight: '600 !important',
                lineHeight: '18.2px',
                textAlign: 'left',
                fontFamily: 'Avenir Next',
              }}
            >
              {expandedData.title}
            </Typography>
            <Box
              sx={{
                height: 'auto',
                borderRadius: '10px',
                paddingTop: '1px',
              }}
            >
              {' '}
              <List sx={{ padding: '0px', gap: '20px' }}>
                {expandedData.body.map((detail: any, i: any) =>
                  detail.value ? (
                    <ListItem
                      key={i}
                      sx={{
                        height: '22px',
                        marginTop: '20px',
                        paddingInline: '0',
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            color="#9CA0AB"
                            variant="subtitle1"
                            sx={{
                              fontWeight: '500 !important',
                              fontSize: '16px',
                              lineHeight: '21px',
                            }}
                          >
                            {detail.label}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction
                        sx={{ width: '70%', textAlign: 'right' }}
                      >
                        {' '}
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            lineHeight: '21px',
                          }}
                        >
                          {detail?.value?.length > 50
                            ? detail.value.substring(0, 50) + ' ...'
                            : detail.value}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : null
                )}
              </List>
              {Boolean(expandedData.renewal) && (
                <Typography
                  color="#9CA0AB"
                  variant="subtitle1"
                  sx={{
                    maxHeight: '22px',
                    marginTop: '15px',
                    paddingRight: '16px',
                    textAlign: 'right',
                    left: 'right',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 500,
                    color: '#0179FF',
                  }}
                >
                  {expandedData.renewal}
                </Typography>
              )}
            </Box>

            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
          </>
        ))}
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: '#383A49',
            fontSize: '14px',
            fontWeight: '600 !important',
            lineHeight: '18.2px',
            textAlign: 'left',
          }}
        >
          {t('checkoutstep.payement.title')}
        </Typography>

        <StripePayForm />
      </Box>
    </div>
  );
}

export default CheckoutStep;
