import {
  Button,
  ButtonProps,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import InsureProductProcessContext from '../../contexts/InsureProductProcessContext';
export type PrimaryButtonProps = any;

export const PrimaryButton: React.FC<PrimaryButtonProps> = (props = {}) => {
  const { sx, children, disabled, onClick, isLoading, ...otherProps } = props;
  const [loading, setLoading] = useState(true);
  const [startLoading, setStartLoading] = useState<number>(Date.now());

  useEffect(() => {
    if (!props.loadingLogic) return;
    if (loading === isLoading) return;

    if (loading && !isLoading) {
      let end = Date.now();
      if (end - startLoading < 500)
        setTimeout(() => setLoading(isLoading), 500 - (end - startLoading));
    } else if (!loading && isLoading) {
      setLoading(isLoading);
      setStartLoading(Date.now());
    }
  }, [isLoading]);

  return (
    <Button
      variant="contained"
      onClick={(e: any) => {
        if (onClick) {
          if (props.loadingLogic) {
            setLoading(true);
            setStartLoading(Date.now());
            onClick(e);
            setTimeout(() => {
              if (!isLoading) {
                setLoading(false);
              }
            }, 500);
          } else {
            onClick(e);
          }
        }
      }}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '17px 40px',
        gap: '10px',
        position: 'relative',
        width: '100%',
        height: '55px',
        borderRadius: '10px',
        backgroundColor: disabled ? '#9EC8F9' : '#0179FF',
        boxShadow: 'none',
        ...(disabled ? { pointerEvents: 'none' } : {}),
        '&.MuiButtonBase-root:hover': {
          bgcolor: '#0179FF',
          boxShadow: 'none',
        },
        border: 'none',
        ...props.sx,
        textTransform: 'none',
      }}
      {...otherProps}
      style={{ textTransform: 'none' }}
    >
      <Typography
        variant="button"
        sx={{
          color: '#FFFFFF',
          fontStyle: 'Semibold S',
          fontWeight: '600',
          textTransform: 'none',
        }}
      >
        {' '}
        {isLoading && props?.loadingLogic ? (
          <>
            <CircularProgress
              color="inherit"
              size={'25px'}
              sx={{
                position: 'absolute',
                margin: 'auto',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />
          </>
        ) : (
          <>{props.children}</>
        )}
      </Typography>
    </Button>
  );
};

export default PrimaryButton;
