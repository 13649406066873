import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  AccordionDetails,
  Link,
  ListItemSecondaryAction,
  List,
  ListItemIcon,
  Checkbox,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, { useContext, useEffect, useRef, useState } from 'react';
import BlueUnCheckBoxIcon from '../../assets/insurance/blueUnCheckBoxIcon.svg';
import BlueCheckBoxIcon from '../../assets/insurance/blueCheckBoxIcon.svg';
import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';

import { Trans } from 'react-i18next';

function LegalInfo() {
  const {
    t: ContextT,
    data,
    STEPS,
    currentStep,
    setData,
    trackEvent,
  } = useContext<any>(InsuranceDesktopContext);
  const t = (key: string) => ContextT(`legalinfo.${key}`);
  const [downloadedFile, setDowloandedFiles] = useState<boolean>(true);
  const [agreedOn, setAgreedOn] = useState<number[]>(data.agreedOn || []);
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Legal Information',
    });
  }, []);
  useEffect(() => {
    setData({ ...data, agreedOn });
  }, [agreedOn]);

  const aggreements = ['checkboxes.1', 'checkboxes.2', 'checkboxes.3'];
  return (
    <div style={{ marginBottom: '33px' }}>
      <Box
        sx={{
          padding: '32px',
          background: '#FFFFFF',
          marginTop: '24px',
          maxWidth: '408px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          marginInline: 'auto',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
        }}
      >
        <List sx={{ padding: 0 }}>
          {aggreements.map((a: string, index: number) => {
            return (
              <ListItem
                key={index}
                disablePadding
                alignItems="flex-start"
                onClick={() => {
                  console.log(
                    'data?.surveyAnswers?.answers',
                    data?.surveyAnswers?.answers
                  );
                  if (index === 0 && !downloadedFile) {
                    const goldDownload = t('goldDownload').replace(
                      '.zip',
                      '-' + (data?.surveyAnswers?.answers || 'NN') + '.zip'
                    );
                    const platinumDownload = t('platinumDownload').replace(
                      '.zip',
                      '-' + (data?.surveyAnswers?.answers || 'YY') + '.zip'
                    );
                    const link = document.createElement('a');
                    link.href = data?.bike?.premium
                      ? platinumDownload
                      : goldDownload; //`assets/agreementsFiles/platinum/platinum.zip`;
                    link.target = '_blank';
                    link.download = true + '';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setDowloandedFiles(true);
                  }
                  if (agreedOn.includes(index)) {
                    setAgreedOn((prev) => prev.filter((x) => x !== index));
                    trackEvent({
                      event: 'Button click',
                      action: 'Consent uncheck' + +(index + 1),
                    });
                  } else {
                    trackEvent({
                      event: 'Button click',
                      action: 'Consent check' + +(index + 1),
                    });
                    setAgreedOn((prev) => [...prev, index]);
                  }
                }}
                sx={{
                  maxWidth: '100%',
                  paddingTop: index > 0 ? '24px' : '0px',
                  paddingBottom:
                    index < aggreements.length - 1 ? '24px' : '0px',
                  gap: '0px',

                  borderTop: index > 0 ? '1px solid #D9E7F8' : 'none',
                }}
              >
                <ListItemIcon
                  sx={{
                    maxWidth: '32px',
                    minWidth: '32px !important',
                    maxHeight: '24px',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    checkedIcon={
                      <img
                        style={{ width: '24px', height: '24px' }}
                        src={BlueCheckBoxIcon}
                        alt="Checked icon"
                      />
                    }
                    icon={
                      <img
                        style={{ width: '24px', height: '24px' }}
                        src={BlueUnCheckBoxIcon}
                        alt="Unchecked icon"
                      />
                    }
                    sx={{
                      verticalAlign: 'center',
                      width: '24px',
                      height: '24px',
                    }}
                    checked={agreedOn.includes(index)}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    minWidth: 'calc(100% - 32px);',
                    marginTop: '0',
                    cursor: 'pointer',
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      marginTop: '25px',
                      fontSize: '14px',
                      fontWeight: '500',

                      lineHeight: '18px',

                      letterSpacing: '-0.3px',
                      color: '#383A49',
                    }}
                  >
                    <Trans
                      i18nKey={a}
                      t={t}
                      components={{
                        underline: (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();

                              console.log(
                                data?.surveyAnswers,
                                'premium',
                                data?.isPremium,
                                Object.values(data?.surveyAnswers)?.reduce(
                                  (prev: any, curr: any) =>
                                    prev + (curr ? 'Y' : 'N'),
                                  ''
                                )
                              );
                              const goldDownload = t('goldDownload').replace(
                                '.zip',
                                '-' +
                                  Object.values(data?.surveyAnswers)?.reduce(
                                    (prev: any, curr: any) =>
                                      prev + (curr ? 'Y' : 'N'),
                                    ''
                                  ) +
                                  '.zip'
                              );
                              const platinumDownload = t(
                                'platinumDownload'
                              ).replace(
                                '.zip',
                                '-' +
                                  Object.values(data?.surveyAnswers)?.reduce(
                                    (prev: any, curr: any) =>
                                      prev + (curr ? 'Y' : 'N'),
                                    ''
                                  ) +
                                  '.zip'
                              );
                              const link = document.createElement('a');
                              link.href = data?.isPremium
                                ? platinumDownload
                                : goldDownload; //`assets/agreementsFiles/platinum/platinum.zip`;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}
                            style={{
                              textDecoration: 'underline',
                              color: '#0179FF',
                              cursor: 'pointer',

                              //fontSize: '12px',
                            }}
                          >
                            {t(a)}
                          </span>
                        ),
                      }}
                    ></Trans>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}{' '}
        </List>
      </Box>
      <Typography
        sx={{
          marginInline: 'auto',
          maxWidth: STEPS[currentStep].maxWidth,
          marginTop: '25px',
          fontSize: '12px',
          fontWeight: '400',

          lineHeight: '18px',

          letterSpacing: '-0.3px',

          color: '#9CA0AB',
        }}
      >
        {t('extraLegalArtile')}
      </Typography>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '32px',
          justifyContent: 'space-around',
          gap: '14px',
          maxWidth: STEPS[currentStep].maxWidth,
          marginInline: 'auto',
        }}
      >
        <Button
          onClick={() => {
            trackEvent({
              event: 'Button click',
              action: 'Back',
            });
            STEPS[currentStep].previousAction(currentStep);
          }}
          sx={{
            borderRadius: '10px',
            background: 'rgba(156, 160, 171, 0.08)',
            height: '56px',
            width: '235px',
          }}
        >
          <Typography
            sx={{
              color: '#9CA0AB',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '21.6px',
            }}
          >
            {ContextT('back')}
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            background: '#0179FF',
            backgroundColor: '#0179FF',
            borderRadius: '10px',
            height: '56px',
            width: '235px',
            opacity: STEPS[currentStep].canNext ? 1 : 0.5,
          }}
          disabled={agreedOn.length !== aggreements.length}
          onClick={() => {
            trackEvent({
              event: 'Button click',
              action: 'Next',
            });
            STEPS[currentStep].nextAction(currentStep);
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '21.6px',
            }}
          >
            {ContextT('confirm')}
          </Typography>
        </Button>
      </div>
    </div>
  );
}

export default LegalInfo;
