import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    background: 'white !important',
    backgroundColor: 'white !important',
    color: 'white !important',
    border: '1.5px solid rgba(0, 0, 0, 0.38) !important',
    borderRadius: '50%',
    textColor: 'rgba(0, 0, 0, 0.38) !important',
  },
  active: {
    background: 'white !important',
    backgroundColor: 'white !important',
    color: 'white !important',
    border: '1.5px solid #0179FF !important',
    borderRadius: '50%',
    textColor: '#0179FF !important',
  },
  completed: {
    background: 'white !important',
    backgroundColor: 'white !important',
    color: '#0179FF !important',
    border: 'none !important',
    borderRadius: '50%',
    textColor: '#0179FF !important',
  },
});

function MainDesktopScreen(props: any) {
  const {
    currentStep,
    homeStep,
    setHomeStep,
    t,
    STEPS,
    skipUploadScreen,
    trackEvent,
  } = useContext<any>(InsuranceDesktopContext);
  useEffect(() => {
    trackEvent({ event: 'Page Visit', action: 'Landing Page' });
  }, []);
  const HomeSteps = [
    { title: t('priceInfo') },
    { title: t('questionnaire') },
    { title: t('Coverage') },
  ];
  //@ts-ignore
  const classes: any = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        textAlign: 'center',
      }}
    >
      {currentStep > 0 && currentStep < STEPS.length - 1 && (
        <div style={{ marginBottom: '48px' }}>
          <Stepper
            activeStep={currentStep - 1 === 4 ? 3 : currentStep - 1}
            alternativeLabel
            sx={{ width: '527px', marginInline: 'auto' }}
          >
            {STEPS.map(
              (step: any, i: any) =>
                i &&
                i < STEPS.length - (skipUploadScreen ? 1 : 2) && (
                  <Step key={i}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.active,
                          root: classes.root,
                          completed: classes.completed,
                        },
                      }}
                    >
                      {t(`steps.${i}`)}
                    </StepLabel>
                  </Step>
                )
            )}
          </Stepper>
        </div>
      )}
      {currentStep === 0 && (
        <div style={{ marginBottom: '48px' }}>
          <Stepper
            activeStep={homeStep}
            alternativeLabel
            sx={{ width: '527px', marginInline: 'auto' }}
          >
            {HomeSteps.map((step: any, i: any) => (
              <Step key={i}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: classes.active,
                      root: classes.root,
                      completed: classes.completed,
                    },
                  }}
                >
                  {HomeSteps[i].title}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      )}
      <div>
        <Typography
          sx={{ fontSize: '32px', fontWeight: '700', lineHeight: '44.5px' }}
        >
          {currentStep === 0 && HomeSteps[homeStep].title}
          {currentStep !== 0 && currentStep !== 5 && STEPS[currentStep].title}
        </Typography>
      </div>

      <div>{STEPS[currentStep].component}</div>
    </div>
  );
}

export default MainDesktopScreen;
