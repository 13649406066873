import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  AccordionDetails,
  Link,
} from '@mui/material';

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';

import PrimaryButton from '../../components/primary-button/PrimaryButton';
import { isNumber } from 'lodash';
import { EmbeddedInsuranceService } from '@zenown-insurance/services/requests';
import ErrorMessage from '../../components/error-message/ErrorMessage';
const embeddedInsuranceService = new EmbeddedInsuranceService();

const useAddNewProduct = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();

  const addProduct = async (params: any) => {
    setIsLoading(true);
    try {
      const response = await embeddedInsuranceService.addNewProduct({
        ...params,
        shop_id: +(localStorage.getItem('shop_id') || 1),
      });
      setData(response.data);
      setError(null);
      return response;
    } catch (err: any) {
      setError(err);
      return { err };
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, addProduct, setError };
};
function BikeInfo() {
  const { t, data, STEPS, currentStep, setData, setCurrentStep, trackEvent } =
    useContext<any>(InsuranceDesktopContext);
  const {
    data: bikeData,
    isLoading,
    error,
    addProduct,
    setError,
  } = useAddNewProduct();
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Bike Info',
    });
  }, []);
  useEffect(() => {
    if (error)
      setTimeout(() => {
        setError(undefined);
      }, 15 * 1000);
  }, [error]);
  const onsubmit = async () => {
    let { data: quote } = await addProduct({
      //uuid: data?.receipt?.uuid,
      name: data?.bike?.name,
      brand: data?.bike?.brand,
      price: +data?.bike?.price,
      serialNumber: data?.bike?.serial,
      desktop_flow: 1,
    });
    if (quote) {
      setData({
        ...data,
        bike: {
          ...data?.bike,
          ...quote[0],
        },
        quote,
      });
      setCurrentStep(2);
    }
  };
  return (
    <div style={{ marginBottom: '33px' }}>
      {error && <ErrorMessage message={t('shopIdError')} />}
      <Box
        sx={{
          padding: '32px',
          background: '#FFFFFF',
          marginTop: '24px',
          maxWidth: '408px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          marginInline: 'auto',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
        }}
      >
        <label style={{ textAlign: 'left', marginBottom: '11px' }}>
          {t('bikeinfo.brand')}
        </label>
        <TextField
          sx={{ marginBottom: '24px' }}
          value={data?.bike?.brand}
          inputProps={{ tabIndex: 1 }}
          placeholder="Trek"
          onChange={(e) => {
            trackEvent({
              event: 'Input',
              action: 'Bike Brand',
            });
            setData({
              ...data,
              bike: {
                ...data?.bike,
                brand: e.target.value,
              },
            });
          }}
          variant="outlined"
        />
        <label style={{ textAlign: 'left', marginBottom: '11px' }}>
          {t('bikeinfo.model')}
        </label>
        <TextField
          sx={{ marginBottom: '24px' }}
          inputProps={{ tabIndex: 2 }}
          value={data?.bike?.name}
          placeholder="123456"
          onChange={(e) => {
            trackEvent({
              event: 'Input',
              action: 'Bike Model',
            });
            setData({
              ...data,
              bike: {
                ...data?.bike,
                name: e.target.value,
              },
            });
          }}
          variant="outlined"
        />
        <label style={{ textAlign: 'left', marginBottom: '11px' }}>
          {t('bikeinfo.serial')}
        </label>
        <TextField
          sx={{ marginBottom: '24px' }}
          value={data?.bike?.serial}
          inputProps={{ tabIndex: 3 }}
          placeholder="123456"
          onChange={(e) => {
            trackEvent({
              event: 'Input',
              action: 'Bike Serial Number',
            });
            setData({
              ...data,
              bike: {
                ...data?.bike,
                serial: e.target.value,
              },
            });
          }}
          variant="outlined"
        />

        <label style={{ textAlign: 'left', marginBottom: '11px' }}>
          {t('bikeinfo.price')}
        </label>
        <TextField
          value={data?.bike?.price}
          inputProps={{ tabIndex: -1 }}
          sx={{ pointerEvents: 'none' }}
          onChange={(e) => {}}
          variant="outlined"
          placeholder={'0'}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      </Box>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '32px',
          justifyContent: 'space-around',
          gap: '14px',
          maxWidth: STEPS[currentStep].maxWidth,
          marginInline: 'auto',
        }}
      >
        <Button
          onClick={() => {
            trackEvent({
              event: 'Button click',
              action: 'Back',
            });
            STEPS[currentStep].previousAction(currentStep);
          }}
          sx={{
            borderRadius: '10px',
            background: 'rgba(156, 160, 171, 0.08)',
            height: '56px',
            width: '235px',
            '&:hover': {
              background: 'rgba(156, 160, 171, 0.07)',
              backgroundColor: 'rgba(156, 160, 171, 0.07)',
            },
            '&:active': {
              background: 'rgba(156, 160, 171, 0.07)',
              backgroundColor: 'rgba(156, 160, 171, 0.07)',
            },
          }}
        >
          <Typography
            sx={{
              color: '#9CA0AB',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '21.6px',
            }}
          >
            {t('back')}
          </Typography>
        </Button>
        <PrimaryButton
          variant="contained"
          inputProps={{ tabIndex: 5 }}
          isLoading={isLoading}
          loadingLogic={true}
          sx={{
            background: '#0179FF',
            backgroundColor: '#0179FF',

            borderRadius: '10px',
            height: '56px',
            width: '235px',
            opacity: STEPS[currentStep].canNext ? 1 : 0.5,
          }}
          disabled={!STEPS[currentStep].canNext && !error}
          onClick={() => {
            trackEvent({
              event: 'Button click',
              action: 'Next',
            });
            onsubmit();
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '21.6px',
            }}
          >
            {t('next')}
          </Typography>
        </PrimaryButton>
      </div>
    </div>
  );
}

export default BikeInfo;
