import { StrictMode, Suspense } from 'react';
import './styles.scss';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';

console.log(process.env['NX_INSURANCE_API_URL']);

ReactDOM.render(
  <Suspense fallback={<></>}>
    <StrictMode>
      <BrowserRouter>
        {' '}
        <App />
      </BrowserRouter>
    </StrictMode>
  </Suspense>,
  document.getElementById('root')
);
