import { createTheme, Theme } from '@mui/material';
export const createMuiTheme = (): Theme => {
  return createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: `
      @font-face {
        font-family: 'Avenir Next', sans-serif;
        src: local('Avenir Next'), local('Avenir Next'),
        url(../styles/AvenirNextPro/AvenirNextLTPro-Medium.otf) format('opentype');

      `,
      },
      /*MuiInput: {
        styleOverrides: {
          root: {
            border: '1px solid #EDEDED !important',
            borderRadius: '10px',
            padding: '28px 15px 10.5px 15px !important',
            borderBottom: '1px solid #EDEDED !important',
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            input: {
              border: '1px solid #EDEDED !important',
              borderRadius: '10px',
              padding: '28px 15px 10.5px 15px !important',
              borderBottom: '1px solid #EDEDED !important',
            },

            '&.Mui-error': {
              input: {
                border: '1px solid #FF0000 !important',
                color: '#FF0000',
              },
            },
            '&.Mui-focused': {
              input: {
                border: '1px solid #0179FF !important',
                color: '#383A49 !important',
              },
            },
          },
        },
      },

      MuiInputLabel: {
        defaultProps: {
          variant: 'filled',
        },
        styleOverrides: {
          root: {
            background: 'transparent',
            fontFamily: 'Avenir Next',
            fontStyle: 'normal',
            fontWeight: ' 500',
            fontSize: '17px',
            lineHeight: '23px',
            letterSpacing: '-0.3px',
            marginTop: '5px',
            color: '#9CA0AB',
            '&.MuiInputLabel-shrink': {
              color: '#9CA0AB',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '16px',
              letterSpacing: '-0.3px',
              top: '25px',
              transform: 'translate(15px, -25px) scale(1)',
              maxHeight: '16px',
              border: 'none',
              outline: 'none',
              marginTop: '10px',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '> *': {
              border: '1px solid #EDEDED !important',
              borderRadius: '10px',
              padding: '28px 15px 10.5px 15px !important',
              borderBottom: '1px solid #EDEDED !important',
            },
            backgroundColor: '#FFFFFF !important',
            background: '#FFFFFF !important',
            '&&&:before': {
              borderBottom: 'none !important',
            },
            '&&:after': {
              borderBottom: 'none !important',
            },
          },
        },
      },*/
      MuiTextField: {
        styleOverrides: {
          root: {
            height: '57px',
            borderRadius: '8px',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#0179FF',
      },
      secondary: {
        main: '#383A49',
      },
      success: {
        main: '#20D370',
      },
      error: {
        main: '#FF0000',
      },
    },

    typography: {
      fontFamily: ['Avenir Next', 'sans-serif'].join(','),
      allVariants: {
        color: '#383A49',
        fontFamily: 'Avenir Next',
      },
      h1: {
        fontFamily: 'Avenir Next',
        fontWeight: '700',
        fontSize: '26px',
        lineHeight: '32px',
        /* identical to box height, or 123% */
        letterSpacing: '-0.3px',
        fontStyle: 'Bold',
      },
      h2: {
        fontFamily: 'Avenir Next',
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '28px',
        /* identical to box height, or 123% */
        letterSpacing: '-0.3px',
        fontStyle: 'Semibold XL',
      },
      h3: {
        fontFamily: 'Avenir Next',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '17px',
        lineHeight: '23px',
        /* identical to box height, or 123% */
        letterSpacing: '-0.3px',
      },
      h4: {
        fontFamily: 'Avenir Next',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '28px',
        letterSpacing: '-0.3px',
      },
      h5: {
        fontFamily: 'Avenir Next',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '-0.3px',
        color: '#9CA0AB',
        fontStyle: 'Semibold XL',
      },
      subtitle1: {
        fontFamily: 'Avenir Next',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '-0.3px',
        fontStyle: 'Medium S',
      },
      body1: {
        fontFamily: 'Avenir Next',
        fontWeight: '500',
        fontSize: '17px',
        lineHeight: '26px',
        letterSpacing: '-0.3px',
        fontStyle: 'Medium S',
      },
      button: {
        fontFamily: 'Avenir Next',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '-0.3px',
        fontStyle: 'Semibold S',
        textTransform: 'none',
      },
      caption: {
        fontFamily: 'Avenir Next',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '-0.24px',
        fontStyle: 'Medium XS',
      },
    },
  });
};
