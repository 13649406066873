import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  AccordionDetails,
  Link,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Survey2Img from '../../assets/insurance/Survey2Img.png';
import Survey2Img2 from '../../assets/insurance/Survey2Img@2x.png';
import Survey2Img3 from '../../assets/insurance/Survey2Img@3x.png';
import Survey1Img from '../../assets/insurance/Survey1Img.png';
import Survey1Img2 from '../../assets/insurance/Survey1Img@2x.png';
import Survey1Img3 from '../../assets/insurance/Survey1Img@3x.png';
import SurveyOwner from '../../assets/insurance/SurveyOwner.png';
import SurveyOwner2 from '../../assets/insurance/SurveyOwner@2x.png';
import SurveyOwner3 from '../../assets/insurance/SurveyOwner@3x.png';
import SurveyTime from '../../assets/insurance/SurveyTime.png';
import SurveyTime2 from '../../assets/insurance/SurveyTime@2x.png';
import SurveyTime3 from '../../assets/insurance/SurveyTime@3x.png';
import SerialError from '../../assets/insurance/serial-error.png';
import SerialError2 from '../../assets/insurance/serial-error@2x.png';
import SerialError3 from '../../assets/insurance/serial-error@3x.png';
import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';
import SuccessIcon from '../../assets/insurance/SuccessCheckmarkCircle.png';
import SuccessIcon2 from '../../assets/insurance/SuccessCheckmarkCircle@2x.png';
import SuccessIcon3 from '../../assets/insurance/SuccessCheckmarkCircle@3x.png';
import PrimaryButton from '../../components/primary-button/PrimaryButton';
import { isNumber } from 'lodash';
import { EmbeddedInsuranceService } from '@zenown-insurance/services/requests';
import { error } from 'console';
const embeddedInsuranceService = new EmbeddedInsuranceService();

function Questionnaire() {
  const {
    t,
    data,
    STEPS,
    currentStep,
    setData,
    setCurrentStep,
    setHomeStep,
    trackEvent,
  } = useContext<any>(InsuranceDesktopContext);
  const [questionStep, setQuestionStep] = useState(0);
  const [errorScreen, setErrorScreen] = useState(false);
  const [supportScreen, setSupportScreen] = useState(false);
  const [supportResult, setSupportResult] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('+49 ');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Questionnaire q' + (questionStep + 1),
    });
  }, [questionStep]);
  const Questions = [
    {
      question: t('inssurancecalculator.surveyowner'),
      img: (
        <img
          src={SurveyOwner}
          srcSet={`${SurveyOwner} 1x, ${SurveyOwner2} 2x, ${SurveyOwner3} 3x`}
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '20px',
          }}
        />
      ),
      answers: [
        {
          text: t('inssurancecalculator.surveyowneryes'),
          onClick: () => {
            trackEvent({
              event: 'Button click',
              action: 'Yes',
            });
          },
        },
        {
          text: t('inssurancecalculator.surveyownerno'),
          onClick: () => {
            trackEvent({
              event: 'Button click',
              action: 'No',
            });
          },
        },
      ],
      onPrevious: () => {
        setQuestionStep(questionStep - 1);
      },
    },
    {
      question: t('inssurancecalculator.surveytime'),
      vertical: true,
      img: (
        <img
          src={SurveyTime}
          srcSet={`${SurveyTime} 1x, ${SurveyTime2} 2x, ${SurveyTime3} 3x`}
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '20px',
          }}
        />
      ),
      answers: [
        {
          text: t('inssurancecalculator.surveytimeyes'),
          onClick: () => {
            trackEvent({
              event: 'Button click',
              action: 'it is older than 5 years',
            });
          },
        },
        {
          text: t('inssurancecalculator.surveytimeno'),
          onClick: () => {
            trackEvent({
              event: 'Button click',
              action: 'it is not older than 5 years',
            });
            setErrorScreen(true);
            trackEvent({
              event: 'Page Visit',
              action: "Sorry we can't insure",
            });
          },
        },
        {
          text: t('inssurancecalculator.surveytimemaybe'),
          onClick: () => {
            trackEvent({
              event: 'Button click',
              action: "I don't know",
            });
            setSupportScreen(true);
            trackEvent({
              event: 'Page Visit',
              action: 'Give us your phone number',
            });
          },
        },
      ],
      onPrevious: () => {
        setQuestionStep(questionStep - 1);
      },
    },
    {
      question: t('inssurancecalculator.survey1'),
      img: (
        <img
          src={Survey1Img}
          srcSet={`${Survey1Img} 1x, ${Survey1Img2} 2x, ${Survey1Img3} 3x`}
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '20px',
          }}
        />
      ),
      answers: [
        {
          text: t('inssurancecalculator.survey1yes'),
          onClick: () => {
            trackEvent({
              event: 'Button click',
              action: 'Yes',
            });
            setData({
              ...data,
              surveyAnswers: {
                ...data?.surveyAnswers,
                survey1: true,
              },
            });
          },
        },
        {
          text: t('inssurancecalculator.survey1no'),
          onClick: () => {
            setData({
              ...data,
              surveyAnswers: {
                ...data?.surveyAnswers,
                survey1: false,
              },
            });
            trackEvent({
              event: 'Button click',
              action: 'No',
            });
          },
        },
      ],
      onPrevious: () => {
        setQuestionStep(questionStep - 1);
      },
    },
    {
      question: t('inssurancecalculator.survey2'),
      img: (
        <img
          src={Survey2Img}
          srcSet={`${Survey2Img} 1x, ${Survey2Img2} 2x, ${Survey2Img3} 3x`}
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '20px',
          }}
        />
      ),
      answers: [
        {
          text: t('inssurancecalculator.survey2yes'),
          onClick: () => {
            trackEvent({
              event: 'Button click',
              action: 'Yes',
            });
            setData({
              ...data,
              surveyAnswers: {
                ...data?.surveyAnswers,
                survey2: true,
              },
            });
          },
        },
        {
          text: t('inssurancecalculator.survey2no'),
          onClick: () => {
            trackEvent({
              event: 'Button click',
              action: 'No',
            });
            setData({
              ...data,
              surveyAnswers: {
                ...data?.surveyAnswers,
                survey2: false,
              },
            });
          },
        },
      ],
      onPrevious: () => {
        setQuestionStep(questionStep - 1);
      },
    },
  ];
  if (errorScreen)
    return (
      <Box
        sx={{
          padding: '32px',
          background: '#FFFFFF',
          maxWidth: '398px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          marginInline: 'auto',
          position: 'relative',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            width: 'calc(100% - 36px )',
            padding: '18px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Button
            variant="text"
            sx={{ padding: '0px', maxHeight: '25px' }}
            onClick={() => {
              setQuestionStep(1);
              setErrorScreen(false);
            }}
          >
            <Typography
              sx={{
                color: '#9CA0AB',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '25px',
                verticalAlign: 'middle',
              }}
            >
              <KeyboardBackspaceIcon
                sx={{
                  color: '#9CA0AB',
                  verticalAlign: 'middle',
                  width: '20px',
                  marginRight: '8px',
                  marginBottom: '2px',
                }}
              />
              {t('back')}
            </Typography>
          </Button>

          <Typography
            sx={{
              color: '#9CA0AB',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '25px',
              verticalAlign: 'middle',
            }}
          >
            {2}/{Questions.length}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '43px',
          }}
        >
          <img
            src={SerialError}
            srcSet={`${SerialError} 1x, ${SerialError2} 2x, ${SerialError3} 3x`}
            style={{
              width: '70.24px',
              height: '70.24px',
              marginBottom: '24px',
            }}
          />
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '28px',
              color: '#383A49',
              marginBottom: '16px',
            }}
          >
            {t('error.title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '21px',
              color: '#383A49',
              marginBottom: '32px',
            }}
          >
            {t('error.subTitle')}
          </Typography>
          <PrimaryButton
            sx={{}}
            onClick={() => {
              setHomeStep(0);
              setQuestionStep(0);
              setErrorScreen(false);
            }}
          >
            {t('error.close')}
          </PrimaryButton>
        </div>
      </Box>
    );
  if (supportResult)
    return (
      <Box
        sx={{
          padding: '32px',
          background: '#FFFFFF',
          maxWidth: '398px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          marginInline: 'auto',
          position: 'relative',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            marginTop: '43px',
          }}
        >
          <img
            src={SuccessIcon}
            srcSet={`${SuccessIcon} 1x, ${SuccessIcon2} 2x, ${SuccessIcon3} 3x`}
            style={{
              width: '70.24px',
              height: '70.24px',
              marginBottom: '24px',
            }}
          />
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '28px',
              color: '#383A49',
              marginBottom: '16px',
            }}
          >
            {t('support.result.title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '21px',
              color: '#383A49',
              marginBottom: '32px',
            }}
          >
            {t('support.result.subTitle')}
          </Typography>
          <PrimaryButton
            sx={{}}
            onClick={() => {
              setHomeStep(0);
              setQuestionStep(0);
              setSupportScreen(false);
              setSupportResult(false);
            }}
          >
            {t('support.result.close')}
          </PrimaryButton>
        </div>
      </Box>
    );
  if (supportScreen)
    return (
      <Box
        sx={{
          padding: '32px',
          background: '#FFFFFF',
          maxWidth: '398px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          marginInline: 'auto',
          position: 'relative',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            width: 'calc(100% - 36px )',
            padding: '18px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Button
            variant="text"
            sx={{ padding: '0px', maxHeight: '25px' }}
            onClick={() => {
              setQuestionStep(1);
              setSupportScreen(false);
            }}
          >
            <Typography
              sx={{
                color: '#9CA0AB',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '25px',
                verticalAlign: 'middle',
              }}
            >
              <KeyboardBackspaceIcon
                sx={{
                  color: '#9CA0AB',
                  verticalAlign: 'middle',
                  width: '20px',
                  marginRight: '8px',
                  marginBottom: '2px',
                }}
              />
              {t('back')}
            </Typography>
          </Button>

          <Typography
            sx={{
              color: '#9CA0AB',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '25px',
              verticalAlign: 'middle',
            }}
          >
            {2}/{Questions.length}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '43px',
            textAlign: 'left',
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '28px',
              color: '#383A49',
              marginBottom: '16px',
              textAlign: 'left',
            }}
          >
            {t('support.title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '21px',
              color: '#383A49',
              marginBottom: '24px',
              textAlign: 'left',
            }}
          >
            {t('support.subTitle')}
          </Typography>
          <TextField
            autoFocus
            sx={{ minWidth: '250px', width: '100%', marginBottom: '24px' }}
            defaultValue={phoneNumber}
            label={t('support.phonenumber')}
            fullWidth
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
          />
          <PrimaryButton
            isLoading={isLoading}
            disabled={!phoneNumber?.length || isLoading}
            sx={{ marginBottom: '14px' }}
            onClick={async () => {
              try {
                setIsLoading(true);
                const embeddedInsuranceService = new EmbeddedInsuranceService();
                await embeddedInsuranceService.notifyPhoneNumber(phoneNumber);
              } catch (err) {
              } finally {
                setSupportResult(true);
                setSupportScreen(false);
                setIsLoading(false);
              }
            }}
          >
            {t('support.submit')}
          </PrimaryButton>
          <Button
            variant="text"
            disabled={isLoading}
            sx={{
              color: '#383A49',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '28px',
            }}
            onClick={() => {
              setHomeStep(0);
              setQuestionStep(0);
              setSupportScreen(false);
            }}
          >
            {t('support.cancel')}
          </Button>
        </div>
      </Box>
    );
  return (
    <>
      {' '}
      <Box
        sx={{
          padding: '32px',
          background: '#FFFFFF',
          maxWidth: '398px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          marginInline: 'auto',
          position: 'relative',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            width: 'calc(100% - 36px )',
            padding: '18px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Button
            variant="text"
            sx={{ padding: '0px', maxHeight: '25px' }}
            onClick={() => {
              if (questionStep > 0) setQuestionStep(questionStep - 1);
              else setHomeStep(0);
            }}
          >
            <Typography
              sx={{
                color: '#9CA0AB',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '25px',
                verticalAlign: 'middle',
              }}
            >
              <KeyboardBackspaceIcon
                sx={{
                  color: '#9CA0AB',
                  verticalAlign: 'middle',
                  width: '20px',
                  marginRight: '8px',
                  marginBottom: '2px',
                }}
              />
              {t('back')}
            </Typography>
          </Button>

          <Typography
            sx={{
              color: '#9CA0AB',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '25px',
              verticalAlign: 'middle',
            }}
          >
            {questionStep + 1}/{Questions.length}
          </Typography>
        </div>

        {Questions.filter((x: any, i: any) => i === questionStep).map(
          (question: any, i: any) => {
            return (
              <>
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '43px',
                    }}
                  >
                    {question.img}
                    <div>
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontWeight: '600',
                          fontSize: '16px',
                          lineHeight: '25px',
                        }}
                      >
                        {question.question}
                      </Typography>
                    </div>
                    <div
                      style={{
                        marginTop: '20px',
                        flexDirection: question?.vertical ? 'column' : 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: question?.vertical ? 'stretch' : undefined,
                        width: question?.vertical ? '100%' : undefined,
                        gap: question?.vertical ? '8px' : '10px',
                      }}
                    >
                      {question.answers.map((answer: any, i: any) => (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            answer.onClick();
                            if (questionStep < Questions.length - 1)
                              setQuestionStep(questionStep + 1);
                            else {
                              setHomeStep(2);
                              setQuestionStep(0);
                            }
                          }}
                          sx={{
                            height: '47px',
                            color: '#383A49',
                            maxWidth: !question?.vertical ? '107px' : undefined,
                            border: '1px solid #0179FF',
                            borderRadius: '10px',
                          }}
                        >
                          {answer.text}
                        </Button>
                      ))}
                    </div>
                  </div>
                </>
              </>
            );
          }
        )}
      </Box>
    </>
  );
}

export default Questionnaire;
