import { ThemeProvider } from '@mui/material';

import { InsureProductProcessContextProvider } from '../contexts/InsureProductProcessContext';
import InsureProductPage from '../pages/zenown-insurance/InsureProductPage/InsureProductPage';
import { createMuiTheme } from './zenown-insurance/muiThemeCreation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SerialNumberHow from '../pages/zenown-insurance/InsureProductPage/components/SerialNumberHow';
import InsuranceDesktopLayout from '../components/zenown-insurance/InsuranceDesktopLayout';
import InsuranceDesktopContext from '../contexts/InsuranceDesktopContext';
import MainDesktopScreen from '../pages/desktopInsurance/MainDesktopScreen';

export interface ZenownInsuranceContainerProps {
  path?: 'serial-number';
}
const theme = createMuiTheme();

export function ZenownInsuranceContainer(props: ZenownInsuranceContainerProps) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          {' '}
          <InsuranceDesktopLayout>
            {' '}
            <InsuranceDesktopContext>
              <MainDesktopScreen />
            </InsuranceDesktopContext>
          </InsuranceDesktopLayout>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default ZenownInsuranceContainer;
