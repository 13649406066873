import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  AccordionDetails,
  Link,
  ListItemSecondaryAction,
  List,
} from '@mui/material';
import Folder from '../../assets/insurance/Folder.png';
import Folder2 from '../../assets/insurance/Folder@2x.png';
import Folder3 from '../../assets/insurance/Folder@3x.png';
import CheckIcon from '@mui/icons-material/Check';
import React, { useContext, useEffect, useRef, useState } from 'react';
import QuestionMark from '../../assets/insurance/QuestionMark.png';
import QuestionMark2 from '../../assets/insurance/QuestionMark@2x.png';
import QuestionMark3 from '../../assets/insurance/QuestionMark@3x.png';
import GoldSheild from '../../assets/insurance/gold-sheild.png';
import GoldSheild2 from '../../assets/insurance/gold-sheild@2x.png';
import GoldSheild3 from '../../assets/insurance/gold-sheild@3x.png';
import PlatSheild from '../../assets/insurance/plat-sheild.png';
import PlatSheild2 from '../../assets/insurance/plat-sheild@2x.png';
import PlatSheild3 from '../../assets/insurance/plat-sheild@3x.png';
import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';
import Survey2Img from '../../assets/insurance/Survey2Img.png';
import Survey2Img2 from '../../assets/insurance/Survey2Img@2x.png';
import Survey2Img3 from '../../assets/insurance/Survey2Img@3x.png';
import Survey1Img from '../../assets/insurance/Survey1Img.png';
import Survey1Img2 from '../../assets/insurance/Survey1Img@2x.png';
import Survey1Img3 from '../../assets/insurance/Survey1Img@3x.png';
import ErrorIcon from '../../assets/insurance/serial-error.png';
import ErrorIcon2 from '../../assets/insurance/serial-error@2x.png';
import ErrorIcon3 from '../../assets/insurance/serial-error@3x.png';
import SuccessIcon from '../../assets/insurance/serial-success.png';
import SuccessIcon2 from '../../assets/insurance/serial-success@2x.png';
import SuccessIcon3 from '../../assets/insurance/serial-success@3x.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrimaryButton from '../../components/primary-button/PrimaryButton';
import { Footer } from '../../components/footer/Footer';
import { isNumber } from 'lodash';
import PayementCompleted from '../zenown-insurance/InsureProductPage/components/PayementCompleted';
import DeleteIcon from '@mui/icons-material/Delete';

import { useDropzone } from 'react-dropzone';

import moment from 'moment';
import {
  getInsurancePriceForQuote,
  getPriceFormatted,
} from '@zenown-insurance/services/requests';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { EmbeddedInsuranceService } from '@zenown-insurance/services/requests';
const embeddedInsuranceService = new EmbeddedInsuranceService();

function ProcessCompleted() {
  const {
    t,
    data,
    STEPS,
    currentStep,
    setData,
    setCurrentStep,
    setHomeStep,
    isLoading,
    setIsLoading,
    skipUploadScreen,
  } = useContext<any>(InsuranceDesktopContext);
  const [imageURL, setImageURL] = useState(null);
  const [fileName, setFileName] = useState('');
  const [submittedFile, setSubmittedFile] = useState(false);

  const file = data?.receipt;
  const setFile = (file: any) => {
    setData((data: any) => ({ ...data, receipt: file }));
  };
  const uploadFile = async () => {
    try {
      setIsLoading(true);
      const receiptData: any = await embeddedInsuranceService.getReceipt(
        data?.bike?.uuid
      );
      setData({ ...data, receipt: receiptData?.data });
      if (receiptData?.data?.uploadURL) {
        if (file) {
          const embeddedInsuranceService = new EmbeddedInsuranceService();
          //const newFile = await constraintImage([{ file }], 100, 1);
          // setFile(newFile[0]?.file);

          let binaryFile: any;
          await fetch(file.data_url).then(
            async (res) => (binaryFile = await res.blob())
          );
          const options = {
            onUploadProgress: (progressEvent: any) => {
              const { loaded, total } = progressEvent;
              let percent = Math.floor((loaded * 100) / total);
            },
          };
          await embeddedInsuranceService.uploadFile(
            receiptData?.data?.uploadURL + '',
            binaryFile,
            options
          );
          const notification: any =
            await embeddedInsuranceService.notifyWithTheUpload(
              receiptData?.data?.uuid + ''
            );
          setSubmittedFile(true);
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleFileChange = () => {
    try {
      if (file?.length && file[0].file) setFile(file);
      if (file) {
        const reader: any = new FileReader();
        reader.onloadend = () => {
          setImageURL(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (file) {
      handleFileChange();
      if (file.name) setFileName(file.name);
    }
  }, [file]);

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      const uploadedFile = acceptedFiles[0];
      console.log('Accepted file:', uploadedFile);
      setFile(uploadedFile);

      // Handle file upload here, e.g., upload to a server or save to local state
    }
  };
  const clearFile = (e: any) => {
    setFile(null);
    e.stopPropagation();
    setFile(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*,application/pdf' as any,
    maxFiles: 1,
    multiple: false,
  });
  const isPDF = file && file.type === 'application/pdf';
  const [openHow, setOpenHow] = useState(false);
  let quote: any = {
    price: data?.bike?.subscriptionPrice,
    premium: data?.isPremium,
    premiumPrice: data?.bike?.premiumSubscriptionPrice,
  };
  let item = {
    price: data?.bike?.price,
    brand: data?.bike?.model,
    ...data?.bike,
  };
  const paragraphs = [
    {
      title: t('processcompleted.insurancedetails'),
      body: [
        {
          label: t('checkoutstep.articles.1.package'),
          value: quote?.premium
            ? t('checkoutstep.articles.1.platinum')
            : t('checkoutstep.articles.1.gold'),
        },

        {
          label: t('checkoutstep.articles.1.product'),
          value: item?.brand ? item?.brand + ' ' + item?.name : item?.name,
        },
        {
          label: t('checkoutstep.articles.1.price'),
          value: getPriceFormatted(item?.price || 0),
        },
        {
          label: t('checkoutstep.articles.1.premiumPrice'),
          value: getInsurancePriceForQuote(quote),
        },
        {
          label: t('checkoutstep.articles.1.start'),
          value: moment().format('DD.MM.YYYY'),
        },
      ],
      renewal: t('checkoutstep.articles.1.renewal'),
    },
  ];
  return (
    <div style={{ marginBottom: '33px' }}>
      <div
        style={{ marginInline: 'auto', textAlign: 'center', marginTop: '10px' }}
      >
        <img
          src={SuccessIcon}
          srcSet={`${SuccessIcon} 1x, ${SuccessIcon2} 2x, ${SuccessIcon3} 3x`}
          style={{ width: '42px', height: '42px', marginBottom: '20px' }}
        />
        <Typography
          variant="h1"
          sx={{ marginInline: '40px', paddingInline: '15px' }}
        >
          {t('processcompleted.title')}
        </Typography>
        <Typography variant="h3" sx={{ marginTop: '15px', fontWeight: '400' }}>
          {t('processcompleted.subTitle') +
            ' ' +
            (item?.brand ? item?.brand + ' ' + item?.name : item?.name) +
            ' ' +
            t('processcompleted.bike')}
        </Typography>
      </div>
      <Box
        sx={{
          padding: '32px',
          background: '#FFFFFF',

          marginTop: '40px',
          width: '490px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          marginInline: 'auto',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
        }}
      >
        {' '}
        {paragraphs.map((expandedData: any, i: any) => (
          <>
            <Typography
              sx={{
                textTransform: 'uppercase',
                color: '#383A49',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '18.2px',
                textAlign: 'left',
              }}
            >
              {expandedData.title}
            </Typography>
            <Box
              sx={{
                height: 'auto',
                borderRadius: '10px',
                paddingTop: '1px',
              }}
            >
              {' '}
              <List sx={{ padding: '0px', gap: '20px' }}>
                {expandedData.body.map((detail: any, i: any) =>
                  detail.value ? (
                    <ListItem
                      key={i}
                      sx={{
                        height: '22px',
                        marginTop: '20px',
                        paddingInline: '0',
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            color="#9CA0AB"
                            variant="subtitle1"
                            sx={{
                              fontWeight: '500',
                              fontSize: '16px',
                              lineHeight: '21px',
                            }}
                          >
                            {detail.label}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction
                        sx={{ width: '70%', textAlign: 'right' }}
                      >
                        {' '}
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            lineHeight: '21px',
                          }}
                        >
                          {detail?.value?.length > 50
                            ? detail.value.substring(0, 50) + ' ...'
                            : detail.value}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : null
                )}
              </List>
              {Boolean(expandedData.renewal) && (
                <Typography
                  color="#9CA0AB"
                  variant="subtitle1"
                  sx={{
                    maxHeight: '22px',
                    marginTop: '15px',
                    paddingRight: '16px',
                    textAlign: 'right',
                    left: 'right',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 500,
                    color: '#0179FF',
                  }}
                >
                  {expandedData.renewal}
                </Typography>
              )}
            </Box>

            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
          </>
        ))}
        <label style={{ textAlign: 'left', marginBottom: '11px' }}>
          {t('processcompleted.soldBy')}
        </label>
        <TextField
          value={data?.soldBy}
          onChange={(e) => {
            setData({ ...data, soldBy: e.target.value });
          }}
          variant="outlined"
          placeholder={'Joe'}
        />
      </Box>

      {skipUploadScreen && (
        <Box
          sx={{
            background: '#FFFFFF',
            marginTop: '16px',
            width: '528px',

            marginInline: 'auto',
            paddingInline: '17px',
            paddingTop: '12px',
            height: '84px',

            boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
            borderRadius: '9px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: file ? '0px' : '32px',
            cursor: !file ? 'pointer' : 'default',
          }}
          {...(!file ? { ...getRootProps() } : {})}
        >
          {!file && (
            <div style={{}}>
              <img
                src={Folder}
                srcSet={`${Folder} 1x, ${Folder2} 2x, ${Folder3} 3x`}
                style={{
                  width: '38px',
                  height: '38px',
                  marginInline: 'auto',
                }}
              />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '9px',

              textAlign: 'left',
              minWidth: '292px',
            }}
          >
            {file ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '15px',
                  }}
                >
                  {isPDF ? (
                    <PictureAsPdfIcon
                      sx={{
                        width: '38px',
                        height: '38px',
                      }}
                    />
                  ) : (
                    <img
                      src={imageURL + ''}
                      alt={file.name}
                      style={{
                        maxWidth: '38px',
                        maxHeight: '38px',
                      }}
                    />
                  )}{' '}
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '21px',
                      position: 'relative',
                    }}
                  >
                    {fileName}{' '}
                    {!submittedFile && (
                      <IconButton
                        onClick={clearFile}
                        color="primary"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '21px',
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    background:
                      'linear-gradient(42.57deg, #57CC99 12.95%, #80ED99 90.11%)',
                    borderRadius: '5px',
                    padding: '4px 16px',
                    maxWidth: 'fit-content',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '11px',
                      fontWeight: '500',
                      lineHeight: '21px',
                      color: 'white',
                      pointerEvents: 'none',
                      cursor: 'default',
                    }}
                  >
                    {t('inssurancecalculator.recommended')}
                  </Typography>
                </div>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '21px',
                  }}
                >
                  {t('uploadText')}
                </Typography>
              </>
            )}
          </div>
          {!file ? (
            <Button
              variant="contained"
              sx={{
                borderRadius: '10px',
                height: '47px',
                paddingInline: '28.5px',
              }}
            >
              <input {...getInputProps()} />
              {t('upload')}
            </Button>
          ) : submittedFile ? (
            <div
              style={{
                background:
                  'linear-gradient(42.57deg, #57CC99 12.95%, #80ED99 90.11%)',
                borderRadius: '5px',
                padding: '4px 16px',
                maxWidth: 'fit-content',
              }}
            >
              <Typography
                sx={{
                  fontSize: '11px',
                  fontWeight: '500',
                  lineHeight: '21px',
                  color: 'white',
                }}
              >
                {t('uploaded')}
              </Typography>
            </div>
          ) : (
            <PrimaryButton
              variant="contained"
              isLoading={isLoading}
              loadingLogic={true}
              sx={{
                borderRadius: '10px',
                height: '47px',
                paddingInline: '10.5px',
                opacity: data?.receipt ? 1 : 0.5,
                maxWidth: '112px',
              }}
              disabled={data?.receipt ? false : true}
              onClick={() => {
                uploadFile();
              }}
            >
              {t('submit')}
            </PrimaryButton>
          )}
        </Box>
      )}

      <div style={{ marginTop: '24px', marginInline: 'auto' }}>
        <Typography
          variant="h3"
          sx={{
            marginBottom: '11px',
            marginTop: '30px',
            fontWeight: '400',
            color: '#9CA0AB',
          }}
        >
          {t('processcompleted.anyQuestions') + ' '}
          <Link
            underline="none"
            href="https://wa.me/+4915904367183"
            target="_blank"
          >
            {t('processcompleted.contactus')}
          </Link>
        </Typography>
      </div>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '24px',
          justifyContent: 'space-around',
          gap: '14px',
          maxWidth: STEPS[currentStep].maxWidth,
          marginInline: 'auto',
        }}
      >
        <PrimaryButton
          loadingLogic={true}
          sx={{ marginBottom: '20px', marginTop: '20px', maxWidth: '408px' }}
          onClick={() => {
            setData({});
            setCurrentStep(0);
            setHomeStep(0);
          }}
        >
          {t('done')}
        </PrimaryButton>
      </div>
    </div>
  );
}

export default ProcessCompleted;
