import { ApiServices } from './api-services';
import { environment } from '@env-api/environment';
const mixpanel = require('mixpanel-browser');
mixpanel.init(process.env['NX_MIXPANEL_TOKEN'], { debug: true });
export interface AnalyticsInterface {
  project_id: string;
  journey_id: string;
  event: string;
  action?: string;
  label?: string;
}

export class AnalyticsService {
  private _apiServices: ApiServices = new ApiServices(environment);

  public set apiServices(apiService: ApiServices) {
    this._apiServices = apiService;
  }
  public get apiServices() {
    return this._apiServices;
  }

  // Analytics Section
  public tractEvent = async (
    analyticsData: AnalyticsInterface
  ): Promise<any> => {
    /* return new Promise((res) => {
     setTimeout(() => {
        console.log('analytics', analyticsData);
      }, 0);
    });
    return new Promise((res) => {
      setTimeout(() => {
        console.log('analytics', analyticsData);
        res(analyticsData);
      }, 0);
    });*/
    //return this.apiServices.postRequest('analytics', analyticsData);
    // if (
    //   localStorage.getItem('cookies_store') &&
    //   JSON.parse(localStorage.getItem('cookies_store') + '')?.length === 4 &&
    //   JSON.parse(localStorage.getItem('cookies_store') + '')[1] + '' === 'true'
    // ) {

    return await mixpanel.track(analyticsData?.event, {
      ...analyticsData,
      shop: +(localStorage.getItem('shop_id') || 1),
      where: analyticsData?.action,
    });
  };
}
