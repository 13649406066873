import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { namespaces } from '@zenown-insurance/i18n-service';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomePage from '../pages/desktopInsurance/HomePage';
import BikeInfo from '../pages/desktopInsurance/BikeInfo';
import UserInfo from '../pages/desktopInsurance/UserInfo';
import LegalInfo from '../pages/desktopInsurance/LegalInfo';
import CheckoutStep from '../pages/desktopInsurance/CheckoutStep';
import ReceiptInfo from '../pages/desktopInsurance/ReceiptInfo';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {
  AnalyticsService,
  EmbeddedInsuranceService,
} from '@zenown-insurance/services/requests';

import ProcessCompleted from '../pages/desktopInsurance/ProcessCompleted';
import { useHistory, useLocation } from 'react-router-dom';
export const InsuranceDesktopContext = React.createContext<any>({});
const skipUploadScreen = false;
const analyticsService = new AnalyticsService();
const InsuranceDesktopContextProvider = ({ children }: any) => {
  const history = useHistory();
  const location = useLocation();
  const removeSearchParams = () => {
    const shop: any = location.pathname.split('/')[1];
    if (shop && !isNaN(shop)) {
      localStorage.setItem('shop_id', shop);
    }

    history.replace({
      pathname: '/',
    });
  };
  useEffect(() => {
    removeSearchParams();
  }, []);
  const embeddedInsuranceService = new EmbeddedInsuranceService();
  const [homeStep, setHomeStep] = useState(0);
  const { t, i18n } = useTranslation(namespaces.pages.desktop);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [stripe, setStripe] = useState<any>();
  const [elements, setElements] = useState<any>();
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const trackEvent = async (data: {
    event: string;
    action: string;
    label?: string;
  }) => {
    let queriesFromStorage = localStorage.getItem('queries')
      ? JSON.parse(localStorage.getItem('queries') + '')
      : {};
    return await analyticsService.tractEvent({
      //project_id: 'EMBEDDED_INSURANCE_APP',
      //journey_id: /*localStorage.getItem('journey_id') ||*/ uuid(),
      event: data?.event,
      action: data?.action,
      //label: data?.label,
      ...queriesFromStorage,
    });
  };

  const STEPS = [
    {
      title: t('inssurancecalculator.title'),
      component: <HomePage {...data} />,
      maxWidth: '846px',
      canNext: data?.bike?.price > 0,
      nextAction: (step: number) => goToStep(step + 1),
      previousAction: (step: number) => goToStep(step - 1),
    },
    {
      title: t('bikeinfo.title'),
      component: <BikeInfo />,
      maxWidth: '558px',
      canNext: data?.bike?.price && data?.bike?.serial && data?.bike?.name,
      nextAction: (step: number) => goToStep(step + 1),
      previousAction: (step: number) => {
        setHomeStep(0);
        goToStep(step - 1);
      },
    },
    {
      title: t('personalinfo.title'),
      component: <UserInfo />,
      maxWidth: '558px',
      canNext: true,
      nextAction: (step: number) => goToStep(step + 1),
      previousAction: (step: number) => goToStep(step - 1),
    },
    {
      title: t('legalinfo.title'),
      component: <LegalInfo />,
      maxWidth: '558px',
      canNext: true,
      nextAction: (step: number) => goToStep(step + 1),
      previousAction: (step: number) => goToStep(step - 1),
    },
    {
      title: t('checkoutstep.title'),
      component: <CheckoutStep />,
      maxWidth: '558px',
      canNext: true,
      nextAction: (step: number) => goToStep(step + 1),
      previousAction: (step: number) => goToStep(step - 1),
    },
    {
      title: t('receiptinfo.title'),
      component: <ReceiptInfo />,
      maxWidth: '558px',
      canNext: true,
      nextAction: (step: number) => goToStep(step + 1),
      previousAction: (step: number) => goToStep(step - 1),
      skip: skipUploadScreen,
    },
    {
      title: '',
      component: <ProcessCompleted />,
      maxWidth: '558px',
      canNext: true,
      nextAction: (step: number) => goToStep(step + 1),
      previousAction: (step: number) => goToStep(step - 1),
    },
  ].filter((x) => !x?.skip);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState<number[]>([]);
  const [applePayPaymentRequest, setApplePayPaymentRequest] = useState<any>();
  const [paymentParams, setPaymentParams] = useState<any>();
  const goToStep = (step: number) => {
    setCurrentStep(step);
  };

  const registerStep = (step: any) => {
    setSteps((prevSteps) => [...prevSteps, step]);
  };

  const unregisterStep = (step: any) => {
    setSteps((prevSteps) => prevSteps.filter((s) => s !== step));
  };
  async function initializeAppleGooglePay(params: any, stripe: Stripe) {
    //Apple and Google pay integration

    const p = {
      country: params.country,
      currency: params.currency,
      total: {
        label: params.label,
        amount: Math.floor(params.amount),
      },
    };

    try {
      const paymentRequest = stripe.paymentRequest(p);

      console.log('😎 Params sent to paymentRequest');
      console.log(p);

      if (await paymentRequest.canMakePayment()) {
        console.log('😎 Payment request setup for Apple/Google pay');
        setApplePayPaymentRequest(paymentRequest);
      } else {
        console.log('Device does not have Apple/Google pay available');
        setApplePayPaymentRequest(undefined);
      }
    } catch (e) {
      console.log('❌ CheckAppleGooglePay err: ', e);
    }
  }

  const setupPayement = async (policy_id: any) => {
    return await embeddedInsuranceService
      .getClientSecret({ policy_id })
      .then(async (res: any) => {
        console.log('😎 getting client secret :', res);
        const { clientSecret, country, label, currency, amount } = res;
        const stripeClient = await loadStripe(
          'pk_test_51KW1G0L9RDT5VTK3PpmG7fStpUu6aDLvD5NXVFEn7ra3a5ULrmomo7qA8sGHw12aiHbRsS0WbTuA9YIPGdoM4NXh00jzzwoIhU'
        );
        setStripe(stripeClient);

        if (stripeClient == null) {
          console.log('😎 Stripe client is NULL');
          return;
        }

        console.log('😎 Stripe has been setup with client secret');

        setClientSecret(clientSecret);

        const params = {
          country: country,
          label: label,
          currency: currency.toLowerCase(),
          amount: amount,
        };

        setPaymentParams(params);

        await initializeAppleGooglePay(params, stripeClient);
      })
      .catch((err) => {
        console.log('ERR in getting client secret ', err);
        throw err;
      });
  };

  const state = {
    stripe,
    skipUploadScreen,
    setStripe,
    homeStep,
    setHomeStep,
    isLoading,
    setIsLoading,
    elements,
    applePayPaymentRequest,
    setApplePayPaymentRequest,
    setPaymentParams,
    paymentParams,
    setElements,
    clientSecret,
    setClientSecret,
    currentStep,
    steps,
    setCurrentStep,
    goToStep,
    registerStep,
    unregisterStep,
    setupPayement,
    t,
    STEPS,
    data,
    setData,
    trackEvent,
  };
  return (
    <InsuranceDesktopContext.Provider value={state}>
      {children}
    </InsuranceDesktopContext.Provider>
  );
};

export default InsuranceDesktopContextProvider;
