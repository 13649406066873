import { useTranslation, Trans } from 'react-i18next';
import { Grid, Link, Typography } from '@mui/material';
import { namespaces } from '@zenown-insurance/i18n-service';
import ReturnButton from '../return-button/ReturnButton';
import RVLogo from '../../assets/insurance/RV.png';
import RVLogo2 from '../../assets/insurance/RV@2x.png';
import RVLogo3 from '../../assets/insurance/RV@3x.png';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
export interface HowDoesWorksProps {
  onClose: () => void;
}
const BootstrapDialog = styled(Dialog)(({ theme }: any) => ({
  borderRadius: '15px',
  '& .MuiPaper-root': {
    borderRadius: '15px',
  },
  '& .MuiDialogContent-root': {
    padding: '24px',
    paddingInline: '32px',
  },
  '& .MuiDialogActions-root': {
    padding: '24px',
    paddingInline: '32px',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ padding: '24px', paddingInline: '32px' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function CustomizedDialogs({ header, body, footer, setOpen }: any) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        open={true}
        maxWidth="md"
        sx={{ borderRadius: '15px' }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {header}
        </BootstrapDialogTitle>
        <DialogContent dividers>{body}</DialogContent>
        {footer && (
          <DialogActions sx={{ justifyContent: 'flex-start' }}>
            {footer}
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
export function HowDoesWorks(props: any) {
  const { onClose, trackEvent, showPolicy } = props;
  useEffect(() => {
    if (trackEvent)
      trackEvent({
        event: 'Page Visit',
        action: 'How does it work?',
      });
  }, []);

  const { t } = useTranslation(namespaces.pages.howPage);
  const { t: common } = useTranslation(namespaces.common);

  const renderSections = () => {
    const sectionTexts = HowDoesWorkParagraps.map(
      (paragraph: { title: string; description: string }, index: number) => {
        return (
          <Grid item key={index + ''} sx={{ marginBottom: '24px' }}>
            <Typography sx={{ marginBottom: '20px' }} variant="h3">
              {index + 1 + '.  ' + t('paragraphs.' + paragraph.title)}
            </Typography>
            <Typography variant="subtitle1">
              <Trans
                i18nKey={'paragraphs.' + paragraph.description}
                components={[
                  <Link
                    style={{
                      cursor: 'pointer',
                      color: '#0179FF',
                      textDecoration: 'underline',
                    }}
                    underline="none"
                    onClick={() => showPolicy(true)}
                  >
                    {t('paragraphs.' + paragraph.description)}
                  </Link>,
                ]}
                t={t}
              ></Trans>
            </Typography>
          </Grid>
        );
      }
    );

    return sectionTexts;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CustomizedDialogs
        setOpen={onClose}
        header={
          <>
            {' '}
            <Typography variant="h1" sx={{ marginBottom: '4px' }}>
              {t('title')}
            </Typography>
            <Typography variant="subtitle1">{t('description')}</Typography>
          </>
        }
        body={
          <>
            {' '}
            {renderSections()}
            <Grid item sx={{ marginBottom: '30px' }}>
              <Typography variant="subtitle1">
                <Trans
                  i18nKey="footer"
                  t={t}
                  components={{
                    email: (
                      <Link href={'mailto:support@zenprotect.io'}>
                        support@zenprotect.io
                      </Link>
                    ),
                  }}
                ></Trans>
              </Typography>
            </Grid>
          </>
        }
        footer={
          <>
            <Typography variant="subtitle1">
              <Link
                underline="none"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  trackEvent({
                    event: 'Button click',
                    action: 'Privacy Policy',
                  });
                  showPolicy(true);
                }}
              >
                {common('privacyPolicy')}
              </Link>
            </Typography>
          </>
        }
      />{' '}
    </>
  );
}

export default HowDoesWorks;
const HowDoesWorkParagraps = [
  {
    title: 'p1.title',
    description: 'p1.description',
  },
  {
    title: 'p2.title',
    description: 'p2.description',
  },
  {
    title: 'p3.title',
    description: 'p3.description',
  },
  {
    title: 'p4.title',
    description: 'p4.description',
  },
  {
    title: 'p5.title',
    description: 'p5.description',
  },
  {
    title: 'p6.title',
    description: 'p6.description',
  },
  {
    title: 'p7.title',
    description: 'p7.description',
  },
];
