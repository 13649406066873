import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ZenOwnLogo from './../../assets/insurance/ZenOwn.png';
import ZenOwnLogo2 from './../../assets/insurance/ZenOwn@2x.png';
import ZenOwnLogo3 from './../../assets/insurance/ZenOwn@3x.png';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';
import PolicyPage from '../../components/zenown-insurance/PolicyPage';
import { AnalyticsService } from '@zenown-insurance/services/requests';

export type InsuranceLayoutProps = {};
const analyticsService = new AnalyticsService();
export const InsuranceDesktopLayout: React.FC<InsuranceLayoutProps> = ({
  children,
}) => {
  const { t } = useTranslation(namespaces.pages.desktop);
  const [privacy, setPrivacy] = useState(false);
  const [show, setShow] = useState(false);
  const trackEvent = async (data: {
    event: string;
    action: string;
    label?: string;
  }) => {
    let queriesFromStorage = localStorage.getItem('queries')
      ? JSON.parse(localStorage.getItem('queries') + '')
      : {};
    return await analyticsService.tractEvent({
      //project_id: 'EMBEDDED_INSURANCE_APP',
      //journey_id: /*localStorage.getItem('journey_id') ||*/ uuid(),
      event: data?.event,
      action: data?.action,
      //label: data?.label,
      ...queriesFromStorage,
    });
  };

  useEffect(() => {
    setTimeout(() => setShow(true), 100);
  }, []);
  if (!show) return <></>;
  //TODO: use theme lyout and use the theme variable for switchign themes in teh future
  return (
    <>
      <Paper
        sx={{
          backgroundColor: '#E5E5E5',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          flexDirection="column"
          minHeight="100vh"
          minWidth="100wh"
          sx={{
            background: 'linear-gradient(180deg, #F8FAFC 0%, #FFFFFF 100%)',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              flexDirection: 'column',
            }}
          >
            <img
              src={ZenOwnLogo}
              srcSet={`${ZenOwnLogo} 1x, ${ZenOwnLogo2} 2x, ${ZenOwnLogo3} 3x`}
              style={{
                marginInline: 'auto',
                marginTop: '40px',
                width: '145.16px',
                height: '31px',
                marginBottom: '60px',
              }}
            />
            <Container
              sx={{
                marginInline: 'auto',
                width: '100%',
                display: 'inline-block',
              }}
            >
              {children}
            </Container>
          </div>

          <div
            style={{
              borderTop: '1px solid rgba(56, 58, 73, 0.1)',

              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '49px',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '19px',
              color: '#383A49',
            }}
          >
            <Typography sx={{ opacity: '0.5', marginLeft: '10%' }}>
              {t('copyright')}
            </Typography>{' '}
            <div style={{ opacity: '0.5', marginRight: '10%' }}>
              <ButtonGroup variant="text" color="secondary">
                {false && (
                  <Button
                    sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '19px',
                      color: '#383A49',
                    }}
                  >
                    {' '}
                    {t('terms')}
                  </Button>
                )}
                <Button
                  sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '19px',
                    color: '#383A49',
                  }}
                  onClick={() => {
                    trackEvent({
                      event: 'Button click',
                      action: 'Privacy Policy',
                    });
                    setPrivacy(true);
                  }}
                >
                  {t('privacy')}
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </Box>
        {privacy && (
          <PolicyPage
            onClose={() => {
              setPrivacy(false);
            }}
          />
        )}
      </Paper>
    </>
  );
};

export default InsuranceDesktopLayout;
