// eslint-disable-next-line @typescript-eslint/no-unused-vars
import '../styles.scss';

import { Switch, Route } from 'react-router-dom';
import { ZenownInsuranceContainer } from '@zenown-insurance/zenown-insurance';
import { useEffect, useState } from 'react';

export function App() {
  return (
    <Switch>
      {' '}
      <Route path="/">
        <ZenownInsuranceContainer />
      </Route>
    </Switch>
  );
}

export default App;
