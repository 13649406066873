//@ts-nocheck
import {
  Elements,
  PaymentElement,
  useElements,
  PaymentRequestButtonElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  getPriceFormatted,
  getInsurancePriceForQuote,
} from '@zenown-insurance/services/requests';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import SheildPicture from '../../assets/insurance/sheild-logo.png';
import SheildPicture2 from '../../assets/insurance/sheild-logo@2x.png';
import SheildPicture3 from '../../assets/insurance/sheild-logo@3x.png';
import {
  Grid,
  Typography,
  TextField,
  Dialog,
  Divider,
  DialogContent,
  ListItemText,
  Button,
  ListItem,
} from '@mui/material';

import React, {
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import PrimaryButton from '../primary-button/PrimaryButton';
import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';
import { EmbeddedInsuranceService } from '@zenown-insurance/services/requests';

import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';

import ErrorCard from '../../assets/insurance/error-card.png';
import ErrorCard2 from '../../assets/insurance/error-card@2x.png';
import ErrorCard3 from '../../assets/insurance/error-card@3x.png';
import SecondaryButton from '../secondary-button/SecondaryButton';
import './stripe.scss';

const StripeForm = () => {
  const [errorDialog, setErrorDialog] = useState<any>(false);
  const [err, setErr] = useState<any>();
  const [errors, setErrors] = useState({
    card: false,
    exp: false,
    cvc: false,
  });
  const [valuesEmpty, setValuesEmpty] = useState({
    card: true,
    exp: true,
    cvc: true,
  });
  const [card, setCard] = useState<any>(undefined);

  const elements = useElements();

  useEffect(() => {}, [elements]);
  const {
    setIsLoading,
    clientSecret,
    stripe,
    paymentParams,
    applePayPaymentRequest,
    t,
    data,
    STEPS,
    currentStep,
    setData,
    isLoading,
    trackEvent,
  } = useContext(InsuranceDesktopContext);

  const [ready, setReady] = useState({ cart: false, cvc: false, exp: false });
  useEffect(() => {
    console.log(
      'last isloading',
      Object.values(ready).some((x) => !x),
      ready
    );
    setIsLoading(!ready?.cart);
  }, [ready]);

  useEffect(() => {
    console.log('paymentParams', paymentParams);

    if (!(stripe && applePayPaymentRequest)) {
      console.log('😎 Apple/Google Pay option is not available');
      return;
    }

    console.log('😎 Both Stripe and Apple pay option are ready');

    if (paymentParams == null) {
      console.log('😎 We dont have payment params!');
      return;
    }

    console.log('paymentParams', paymentParams);
    console.log('sending params to stripe ', {
      amount: paymentParams.amount,
      currency: paymentParams.currency,
    });

    console.log('😎 Client secret?? ', clientSecret);

    applePayPaymentRequest.on('paymentmethod', async (ev: any) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      console.log('we sending to confirmpayement those params', {
        clientSecret,
        payment_method: { payment_method: ev.paymentMethod.id },
        handleActions: { handleActions: false },
      });

      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        console.log(
          'cant confirmCardPayment  and this is the error : ',
          confirmError
        );

        setErrorMessage("Oops, couldn't complete the payement");
        ev.complete('fail');
        console.log('event failed ');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          console.log('second attempt of stripe clientSecret: ', clientSecret);
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            console.log('second attempt of stripe failed error: ', error);
            setErrorMessage("Oops, couldn't complete the payement");
            console.log('event failed ');
          } else {
            await paymentSuccedded(false);
          }
        } else {
          await paymentSuccedded(false);
        }
      }
    });
  }, [stripe, applePayPaymentRequest]);

  async function paymentSuccedded(refreshPage = false) {
    /*localStorage.setItem(
      'payementData',
      JSON.stringify({
        receiptId: receipt?.uuid,
        policyId: policyId,
        premium: quote?.premium,
      })
    );*/
    console.log("policy_id: data?.policyId + '',", data?.policyId + '');
    const embeddedInsuranceService = new EmbeddedInsuranceService();
    await embeddedInsuranceService.finalizePayement({
      policy_id: data?.policyId + '',
    });
    STEPS[currentStep].nextAction(currentStep);

    if (refreshPage) {
      window.location.reload();
    }
  }

  async function submitStripePayment(event: React.FormEvent<HTMLFormElement>) {
    try {
      trackEvent({
        event: 'Button click',
        action: 'Pay',
      });
      setIsLoading(true);
      event.preventDefault();
      if (!stripe || !elements) {
        console.log('we dont have elements and stripe', stripe, elements);
        return;
      }

      console.log('{{{{{ eleements', elements);

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      });
      console.log('===== HERE ======');
      if (result.error) {
        const error = result.error;
        // Show error to your customer (for example, insufficient funds)
        console.log('===== ERROR RECEIVED ======');
        console.log(result.error.message);

        localStorage.removeItem('payementData');
        setErrorDialog(true);

        if (error.type === 'card_error' || error.type === 'validation_error') {
          setErr(error?.message);
          console.log(error?.message + '');
        } else {
          setErr(t('payementErrors.unexpected'));
          console.log('An unexpected error occurred.');
        }
        setIsLoading(false);
      } else {
        // The payment has been processed!
        console.log('===== ALL GOOD, NO ERROR ======');
        console.log(`Payment intent status ${result.paymentIntent.status}`);

        if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          await paymentSuccedded(false);
        } else {
          setIsLoading(false);
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Dialog
        open={Boolean(errorDialog)}
        keepMounted
        //style={{ minWidth: '345px' }}
        onClose={() => setErrorDialog(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            marginInline: '15px',
            width: '100%',
            borderRadius: '10px',
          },
        }}
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          marginInline: '0px',
          width: '100%',
        }}
      >
        <DialogContent
          sx={{
            //minHeight: '398px',
            //maxHeight: '398px',
            paddingBottom: '24px',
            borderRadius: '10px',
            overflow: 'hidden',
            height: '100%',
            //minWidth: '345px',
            padding: '0px',
            marginBottom: '0px',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginTop: '50px',
              //minWidth: '345px',
              width: '100%',
              paddingInline: '24px',
              marginBottom: '0px',
            }}
          >
            <Grid item sx={{ marginBottom: '20px' }}>
              <img
                src={ErrorCard}
                srcSet={`${ErrorCard} 1x, ${ErrorCard2} 2x, ${ErrorCard3} 3x`}
                style={{
                  width: '110px',
                  height: '96px',
                }}
                alt="Error Card"
              />
            </Grid>
            <Grid item sx={{ marginBottom: '15px' }}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '600',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {t('stripeError')}
              </Typography>
            </Grid>
            <Grid item sx={{ marginBottom: '25px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '500',
                  color: '#9CA0AB',
                  fontSize: '12px',
                  fontHeight: '19px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {err}
              </Typography>
            </Grid>

            <Grid item sx={{ marginBottom: '20px', width: '100%' }}>
              <PrimaryButton
                sx={{ fontStyle: 'Semibold S', width: '100%' }}
                onClick={() => setErrorDialog(false)}
              >
                {t('close')}
              </PrimaryButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <form id="payment-form" onSubmit={submitStripePayment}>
        <div
          id="card"
          style={{
            marginTop: '20px',
            position: 'relative',
            textAlign: 'left',
          }}
        >
          {card && (
            <img
              style={{
                width: '30px',
                position: 'absolute',
                right: '15px',
                top: '48px',
                zIndex: '99',
              }}
              src={window.location.origin + `/assets/cards/${card}.svg`}
            />
          )}
          <label
            style={{
              marginBottom: '11px',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20.8px',
            }}
          >
            Card Number
          </label>
          <div
            style={{
              height: '11px',
              opacity: 0,
            }}
          ></div>
          <TextField
            //
            // variant="filled"
            required
            fullWidth
            sx={{ margin: '0 11 0 11' }}
            error={errors?.card}
            InputLabelProps={{
              sx: {
                marginTop: '6px',
                root: {
                  marginTop: '6px',
                },
                '&.MuiInputLabel-shrink': {
                  marginTop: '14px',
                },
              },
              required: false,
              error: errors?.card,
              shrink: !valuesEmpty?.card ? true : undefined,
            }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                sx: {
                  '&.MuiOutlinedInput-input': { height: '20.4px' },
                },
                id: 'Card Number',
                trackEvent,
                ready: ready?.cart,
                setReady: (e: any) => setReady({ ...ready, cart: true }),
                setError: (b: boolean) => setErrors({ ...errors, card: b }),
                valuesEmpty: valuesEmpty?.card,
                setCard,
                setValuesEmpty: (b: boolean) =>
                  setValuesEmpty({ ...valuesEmpty, card: b }),
                component: CardNumberElement,
                placeholder: '2133 4421 6725 9289',
              },
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            gap: '14px',
            textAlign: 'left',
          }}
        >
          <div
            style={{
              minWidth: '49%',
            }}
          >
            <label
              style={{
                marginBottom: '11px',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20.8px',
              }}
            >
              MM/YY
            </label>{' '}
            <div
              style={{
                height: '11px',
                opacity: 0,
              }}
            ></div>
            <TextField
              required
              fullWidth
              error={errors?.exp}
              InputLabelProps={{
                sx: {
                  marginTop: '6px',
                  root: {
                    marginTop: '6px',
                  },
                  '&.MuiInputLabel-shrink': {
                    marginTop: '14px',
                  },
                },
                error: errors?.exp,
                required: false,
                shrink: !valuesEmpty?.exp ? true : undefined,
              }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  sx: {
                    '&.MuiOutlinedInput-input': { height: '20.4px' },
                  },
                  id: 'MM/YY',
                  trackEvent,
                  ready: ready?.exp,
                  //setReady: (e: any) => setReady({ ...ready, exp: true }),
                  setError: (b: boolean) => setErrors({ ...errors, exp: b }),
                  valuesEmpty: false,
                  setValuesEmpty: (b: boolean) =>
                    setValuesEmpty({ ...valuesEmpty, exp: b }),
                  component: CardExpiryElement,
                  placeholder: '08/24',
                },
              }}
            />
          </div>
          <div
            style={{
              minWidth: '49%',
            }}
          >
            <label
              style={{
                marginBottom: '11px',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20.8px',
              }}
            >
              CVC
            </label>{' '}
            <div
              style={{
                height: '11px',
                opacity: 0,
              }}
            ></div>
            <TextField
              error={errors?.cvc}
              required
              fullWidth
              InputLabelProps={{
                sx: {
                  marginTop: '6px',
                  root: {
                    marginTop: '6px',
                  },
                  '&.MuiInputLabel-shrink': {
                    marginTop: '14px',
                  },
                },
                error: errors?.cvc,
                required: false,
                shrink: !valuesEmpty?.cvc ? true : undefined,
              }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  sx: {
                    '&.MuiOutlinedInput-input': { height: '20.4px' },
                  },
                  id: 'CVC',
                  trackEvent,
                  ready: ready?.cvc,
                  setError: (b: boolean) => setErrors({ ...errors, cvc: b }),
                  // setReady: (e: any) => setReady({ ...ready, cvc: true }),
                  valuesEmpty: false,
                  setValuesEmpty: (b: boolean) =>
                    setValuesEmpty({ ...valuesEmpty, cvc: b }),
                  component: CardCvcElement,
                  placeholder: '123',
                },
              }}
            />
          </div>
        </div>

        {
          //Only show Apple Pay button if applePayPaymentRequest is initialized
          applePayPaymentRequest && (
            <>
              <Divider sx={{ marginBottom: '30px', marginTop: '30px' }}>
                <Typography
                  sx={{
                    fontWeight: '510',
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#383A49',
                  }}
                >
                  OR
                </Typography>
              </Divider>
              <PaymentRequestButtonElement
                options={{
                  paymentRequest: applePayPaymentRequest,
                  style: {
                    paymentRequestButton: {
                      theme: 'dark',
                      height: '50px',
                    },
                  },
                }}
              />
            </>
          )
        }
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '32px',
            justifyContent: 'space-around',
            gap: '14px',
            maxWidth: STEPS[currentStep].maxWidth,
            marginInline: 'auto',
          }}
        >
          {false && (
            <Button
              onClick={() => {
                trackEvent({
                  event: 'Button click',
                  action: 'Back',
                });
                STEPS[currentStep].previousAction(currentStep);
              }}
              sx={{
                borderRadius: '10px',
                background: 'rgba(156, 160, 171, 0.08)',
                height: '56px',
                width: '235px',
              }}
            >
              <Typography
                sx={{
                  color: '#9CA0AB',
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '21.6px',
                }}
              >
                {t('back')}
              </Typography>
            </Button>
          )}
          <PrimaryButton
            type="submit"
            isLoading={isLoading}
            loadingLogic={true}
            disabled={Object.values(errors).some((x) => x === true)}
            loadingLogic={true}
            variant="contained"
            sx={{
              background: '#0179FF',
              backgroundColor: '#0179FF',
              borderRadius: '10px',
              height: '56px',
              // width: '235px',
              opacity: STEPS[currentStep].canNext ? 1 : 0.5,
            }}
            disabled={!STEPS[currentStep].canNext}
          >
            <Typography
              sx={{
                color: 'white',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '21.6px',
                whiteSpace: 'nowrap',
              }}
            >
              {t('pay')}
            </Typography>
          </PrimaryButton>
        </div>
      </form>
    </>
  );
};

const StripeInput = ({
  id,
  component: Component,
  ready,
  setReady,
  setError,
  valuesEmpty,
  setValuesEmpty,
  inputRef,
  setCard,
  showIcon,
  trackEvent,
  ...props
}) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  const { clientSecret } = useContext(InsuranceDesktopContext);
  const options = useMemo(() => {
    return {
      clientSecret,
      //showIcon: valuesEmpty,
      placeholder: props.placeholder,
      style: {
        base: {
          iconColor: '#9CA0AB',
          fontFamily: 'Avenir Next',
          fontWeight: '500',
          fontSize: '17px',

          /* identical to box height, or 153% */

          letterSpacing: '-0.3px',
          '::placeholder': {
            color: '#9CA0AB',
          },
        },
        invalid: {
          iconColor: '#FF2D2D',
          color: '#FF2D2D',
        },
      },
    };

    if (showIcon) {
      o.showIcon = true;
      // o.iconStyle = 'solid';
    }

    return o;
  }, [showIcon]);
  return (
    <>
      <Component
        onReady={(element) => {
          elementRef.current = element;
          if (setReady) setReady(true);
        }}
        {...props}
        placeholder={''}
        options={options}
        onChange={(event: any) => {
          console.log('event', event);

          trackEvent({
            event: 'Input',
            action: id,
          });
          setError(Boolean(event?.error));
          setValuesEmpty(event?.empty ? true : false);
          if (
            setCard &&
            typeof setCard == 'function' &&
            event?.brand &&
            event?.brand !== 'unknown'
          ) {
            setCard(event?.brand + '');
          } else {
            setCard(undefined);
          }
        }}
      />
    </>
  );
};
export const StripePayForm = () => {
  const { stripe, clientSecret: formState } = useContext(
    InsuranceDesktopContext
  );

  const options = useMemo(() => {
    const clientSecret = formState;
    return {
      clientSecret,
    };
  }, [formState]);

  return (
    <div style={{ position: 'relative' }} className="stripe-form">
      <Elements
        stripe={stripe}
        options={{
          locale: localStorage.getItem('i18nextLng'),
          ...{
            ...options,
            fonts: [
              {
                family: 'Avenir Next',
                src: 'url(https://zenown-cdn.s3.eu-central-1.amazonaws.com/fonts/AvenirNextLTPro-Medium.otf)',
                unicodeRange:
                  'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215',
              },
            ],
          },
        }}
      >
        <StripeForm />
      </Elements>
    </div>
  );
};
export default StripePayForm;
