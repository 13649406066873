import { Box, Button, Typography, Link, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Folder from '../../assets/insurance/Folder.png';
import Folder2 from '../../assets/insurance/Folder@2x.png';
import Folder3 from '../../assets/insurance/Folder@3x.png';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { InsuranceDesktopContext } from '../../contexts/InsuranceDesktopContext';
import QR_Code from '../../assets/insurance/QR_Code.png';
import QR_Code2 from '../../assets/insurance/QR_Code@2x.png';
import QR_Code3 from '../../assets/insurance/QR_Code@3x.png';
import EXAMPLE from '../../assets/insurance/EXAMPLE.png';
import EXAMPLE2 from '../../assets/insurance/EXAMPLE@2x.png';
import EXAMPLE3 from '../../assets/insurance/EXAMPLE@3x.png';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';

import { Footer } from '../../components/footer/Footer';
import PrimaryButton from '../../components/primary-button/PrimaryButton';
import { EmbeddedInsuranceService } from '@zenown-insurance/services/requests';
import Resizer from 'react-image-file-resizer';
import { CustomizedDialogs } from '../../components/zenown-insurance/howDoesWorks';
import { Trans } from 'react-i18next';
const embeddedInsuranceService = new EmbeddedInsuranceService();

async function constraintImage(
  d: any,
  quality = 100,
  drop = 0.5
): Promise<any> {
  const resizeFile = async (d: any) =>
    new Promise(async (resolve) => {
      Resizer.imageFileResizer(
        d[0]?.file,
        3500,
        3500,
        'JPEG',
        quality - drop,
        0,
        async (uri: any) => {
          const contentType = uri.split(';base64,')[0]?.replace('data:', '');
          const namesArray = d[0]?.file?.name.split('.');
          namesArray.pop();
          namesArray.push('jpg');

          console.log(`😎😎 Quality ${quality - drop}`);

          const newName = 'COMPRESSED-' + namesArray.join('.');
          d = [
            {
              data_url: uri,
              file: new File([uri], newName, { type: contentType }),
            },
          ];
          console.log('size ', d[0]?.file?.size / 1024 / 1024);
          if (d[0]?.file?.size / 1024 / 1024 > 3) {
            d = await resizeFile(d);
          }
          resolve(d);
        },
        'blob ',
        1000,
        1000
      );
    });
  let result: any;
  if (d && d.length) {
    //TODO: if a condition on size then we resize
    if (d[0]?.file?.size / 1024 / 1024 > 3) {
      result = await resizeFile(d);
    } else {
      result = d;
    }
    if (result) {
      return result;
      //TODO: do recursive here till u have udner 3
    }
  }
}
function ReceiptInfo() {
  const {
    t,
    data,
    STEPS,
    currentStep,
    setData,
    isLoading,
    setIsLoading,
    setCurrentStep,
    setHomeStep,
    trackEvent,
  } = useContext<any>(InsuranceDesktopContext);
  const [imageURL, setImageURL] = useState(null);
  const [fileName, setFileName] = useState('');
  useEffect(() => {
    trackEvent({
      event: 'Page Visit',
      action: 'Purchase Succesful',
    });
  }, []);

  const file = data?.receipt;
  const setFile = (file: any) => {
    setData((data: any) => ({ ...data, receipt: file }));
  };
  const uploadFile = async () => {
    try {
      trackEvent({
        event: 'Button click',
        action: 'Upload picture',
      });
      setIsLoading(true);
      const receiptData: any = await embeddedInsuranceService.getReceipt(
        data?.bike?.uuid
      );
      setData({ ...data, receipt: receiptData?.data });
      if (receiptData?.data?.uploadURL) {
        if (file) {
          const embeddedInsuranceService = new EmbeddedInsuranceService();
          //const newFile = await constraintImage([{ file }], 100, 1);
          // setFile(newFile[0]?.file);

          let binaryFile: any;
          await fetch(file.data_url).then(
            async (res) => (binaryFile = await res.blob())
          );
          const options = {
            onUploadProgress: (progressEvent: any) => {
              const { loaded, total } = progressEvent;
              let percent = Math.floor((loaded * 100) / total);
            },
          };
          await embeddedInsuranceService.uploadFile(
            receiptData?.data?.uploadURL + '',
            binaryFile,
            options
          );
          const notification: any =
            await embeddedInsuranceService.notifyWithTheUpload(
              receiptData?.data?.uuid + ''
            );
          // STEPS[currentStep].nextAction(currentStep);
          setData({});
          setCurrentStep(0);
          setHomeStep(0);
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleFileChange = () => {
    try {
      if (file?.length && file[0].file) setFile(file);
      if (file) {
        const reader: any = new FileReader();
        reader.onloadend = () => {
          setImageURL(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (file) {
      handleFileChange();
      if (file.name) setFileName(file.name);
    }
  }, [file]);

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      const uploadedFile = acceptedFiles[0];
      console.log('Accepted file:', uploadedFile);
      setFile(uploadedFile);

      // Handle file upload here, e.g., upload to a server or save to local state
    }
  };
  const clearFile = (e: any) => {
    e.stopPropagation();
    setFile(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*,application/pdf' as any,
    maxFiles: 1,
    multiple: false,
  });
  const isPDF = file && file.type === 'application/pdf';
  const [openHow, setOpenHow] = useState(false);

  return (
    <div style={{ marginBottom: '33px' }}>
      {openHow && (
        <CustomizedDialogs
          setOpen={setOpenHow}
          header={
            <>
              <Typography variant="h1" sx={{}}>
                {t('receiptinfo.receiptinfo.example.title')}
              </Typography>
            </>
          }
          body={
            <>
              <Typography variant="subtitle1">
                <Trans
                  i18nKey={'receiptinfo.receiptinfo.example.description'}
                  components={{
                    br: <br />,
                  }}
                  t={t}
                >
                  {t('receiptinfo.receiptinfo.example.description')}
                </Trans>
              </Typography>
              <br />
              <img
                src={EXAMPLE}
                srcSet={`${EXAMPLE} 1x, ${EXAMPLE2} 2x, ${EXAMPLE3} 3x`}
                style={{
                  width: '100%',
                  marginBottom: '30px',
                }}
              />
            </>
          }
        />
      )}
      <div
        style={{
          marginInline: 'auto',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '21px',
          marginTop: '16px',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            background:
              'linear-gradient(42.57deg, #57CC99 12.95%, #80ED99 90.11%)',
            borderRadius: '5px',
            maxWidth: 'fit-content',
            maxHeight: '48px',
            width: '100%',
            padding: '13px',
            paddingInline: '142px',
            marginInline: 'auto',
            marginBottom: '22px',
            cursor: 'default',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '21px',
              color: 'white',
            }}
          >
            <CheckIcon
              sx={{
                color: 'white',
                width: '17px',
                marginRight: '19px',
                verticalAlign: 'middle',
              }}
            />
            {t('receiptinfo.success')}
          </Typography>
        </div>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
            lineHeight: '44.5px',
            marginBottom: '24px',
            maxWidth: '550px',
            marginInline: 'auto',
          }}
        >
          {t('receiptinfo.mainTitle')}
        </Typography>
        <Link
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            trackEvent({
              event: 'Page Visit',
              action: 'Good example window',
            });
            trackEvent({
              event: 'Button click',
              action: 'show me good example',
            });
            setOpenHow(true);
          }}
        >
          {t('receiptinfo.example')}
        </Link>
      </div>

      <Box
        sx={{
          padding: '22px',
          background: '#FFFFFF',
          marginTop: '24px',
          maxWidth: '558px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'stretch',
          marginInline: 'auto',

          boxShadow: '0px 4px 30px 1px rgba(0, 55, 129, 0.03)',
          borderRadius: '10px',
          height: '310px',
        }}
      >
        <div
          style={{
            width: '50%',
            background: '#F8FBFF',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            /* primary/60 */

            border: '2px dashed #E0EFFF',
            borderRadius: '10px',
            cursor: 'pointer',
          }}
        >
          <div
            {...getRootProps()}
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              margin: 'auto',
            }}
          >
            <input {...getInputProps()} />
            <div style={{}}>
              {file ? (
                isPDF ? (
                  <PictureAsPdfIcon
                    sx={{
                      width: '46px',
                      height: '46px',
                      marginInline: 'auto',
                    }}
                  />
                ) : (
                  <img
                    src={imageURL + ''}
                    alt={file.name}
                    style={{
                      maxWidth: '46px',
                      maxHeight: '46px',
                      marginInline: 'auto',
                    }}
                  />
                )
              ) : (
                <img
                  src={Folder}
                  srcSet={`${Folder} 1x, ${Folder2} 2x, ${Folder3} 3x`}
                  style={{
                    width: '46px',
                    height: '46px',
                    marginInline: 'auto',
                  }}
                />
              )}

              {file ? (
                <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '21px',
                      position: 'relative',
                      marginTop: '10px',
                    }}
                  >
                    {fileName}{' '}
                    <IconButton
                      onClick={clearFile}
                      color="primary"
                      sx={{
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '21px',
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Typography>{' '}
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '21px',
                    }}
                  >
                    {t('receiptinfo.folder.title')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '21px',
                      color: '#9CA0AB',
                    }}
                  >
                    {t('receiptinfo.folder.subTitle')}
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>
        {false && (
          <div
            style={{
              width: '50%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              cursor: 'pointer',
            }}
          >
            <img
              src={QR_Code}
              srcSet={`${QR_Code} 1x, ${QR_Code2} 2x, ${QR_Code3} 3x`}
              style={{
                width: '86px',
                height: '85px',
                marginInline: 'auto',
              }}
            />
            <Typography
              sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px' }}
            >
              {t('receiptinfo.qr.title')}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '21px',
                color: '#9CA0AB',
              }}
            >
              {t('receiptinfo.qr.subtitle')}
            </Typography>
          </div>
        )}
      </Box>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '32px',
          justifyContent: 'space-around',
          gap: '14px',
          maxWidth: STEPS[currentStep].maxWidth,
          marginInline: 'auto',
        }}
      >
        {false && (
          <Button
            onClick={() => {
              STEPS[currentStep].previousAction(currentStep);
            }}
            sx={{
              borderRadius: '10px',
              background: 'rgba(156, 160, 171, 0.08)',
              height: '56px',
              width: '235px',
            }}
          >
            <Typography
              sx={{
                color: '#9CA0AB',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '21.6px',
              }}
            >
              {t('back')}
            </Typography>
          </Button>
        )}
        <PrimaryButton
          variant="contained"
          isLoading={isLoading}
          loadingLogic={true}
          sx={{
            background: '#0179FF',
            backgroundColor: '#0179FF',
            borderRadius: '10px',
            height: '56px',
            width: '279px',
            opacity: data?.receipt ? 1 : 0.5,
          }}
          disabled={data?.receipt ? false : true}
          onClick={() => {
            uploadFile();
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '21.6px',
            }}
          >
            {t('receiptinfo.checkout')}
          </Typography>
        </PrimaryButton>
      </div>
      <div
        style={{
          marginInline: 'auto',
          marginTop: '32px',
          marginBottom: '48px',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '21px',
        }}
      >
        {' '}
        <Link
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            trackEvent({
              event: 'Button click',
              action: 'Add it later',
            });
            // STEPS[currentStep].nextAction(currentStep);
            setData({});
            setCurrentStep(0);
            setHomeStep(0);
          }}
        >
          {t('receiptinfo.additlater')}
        </Link>
      </div>

      <Footer />
    </div>
  );
}

export default ReceiptInfo;
